// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filled-sportline-logo-image_k752_{max-width:100%;max-height:100%}.filled-sportline-logo-flag_RQP9M{box-sizing:initial;overflow:hidden}.filled-sportline-logo-flag--with-border_Evk9W{border:1px solid #0000004d;border-radius:5px}.filled-sportline-logo-icon_FN2fJ{fill:var(--DLayer1)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filled-sportline-logo-image": `filled-sportline-logo-image_k752_`,
	"filled-sportline-logo-flag": `filled-sportline-logo-flag_RQP9M`,
	"filled-sportline-logo-flag--with-border": `filled-sportline-logo-flag--with-border_Evk9W`,
	"filled-sportline-logo-icon": `filled-sportline-logo-icon_FN2fJ`
};
export default ___CSS_LOADER_EXPORT___;
