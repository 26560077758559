import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import StatisticLeagueStandingBlock from 'web/src/modules/sportline/submodules/core-statistic/components/StatisticLeagueStandingBlock.vue';
import StatisticDisclaimer from 'web/src/modules/sportline/submodules/core-statistic/components/StatisticDisclaimer.vue';
import H2hMatchStatisticBlock from '../components/statistic/H2hMatchStatisticBlock.vue';
import LastGamesResultsMatchStatisticBlock from '../components/statistic/LastGamesResultsMatchStatisticBlock.vue';
import useSportEventStatistic from './useSportEventStatistic';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SportEventStatistic',
    setup (__props) {
        const { leagueStandings, teams, h2hMatchStatistic, joinMatches, hostMatches, guestMatches, statisticSportFamily } = useSportEventStatistic();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['sport-event-statistic'])
            }, [
                _unref(teams) && (_unref(joinMatches).length > 0 || _unref(h2hMatchStatistic)) ? (_openBlock(), _createBlock(H2hMatchStatisticBlock, {
                    key: 0,
                    teams: _unref(teams),
                    "join-matches": _unref(joinMatches),
                    "h2h-match-statistic": _unref(h2hMatchStatistic),
                    "sport-family": _unref(statisticSportFamily)
                }, null, 8, [
                    "teams",
                    "join-matches",
                    "h2h-match-statistic",
                    "sport-family"
                ])) : _createCommentVNode("", true),
                _unref(teams) && (_unref(hostMatches).length > 0 || _unref(guestMatches).length > 0) ? (_openBlock(), _createBlock(LastGamesResultsMatchStatisticBlock, {
                    key: 1,
                    teams: _unref(teams),
                    "host-matches": _unref(hostMatches),
                    "guest-matches": _unref(guestMatches),
                    "sport-family": _unref(statisticSportFamily)
                }, null, 8, [
                    "teams",
                    "host-matches",
                    "guest-matches",
                    "sport-family"
                ])) : _createCommentVNode("", true),
                _unref(teams) && _unref(leagueStandings).length > 0 ? (_openBlock(), _createBlock(StatisticLeagueStandingBlock, {
                    key: 2,
                    class: _normalizeClass(_ctx.$style['sport-event-statistic__league-standings']),
                    teams: _unref(teams),
                    "league-standings": _unref(leagueStandings)
                }, null, 8, [
                    "class",
                    "teams",
                    "league-standings"
                ])) : _createCommentVNode("", true),
                _createVNode(StatisticDisclaimer)
            ], 2)), [
                [
                    _directive_auto_id,
                    'SportEventStatistic'
                ]
            ]);
        };
    }
});
