// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sportline-breadcrumbs__inner_bX403{display:flex;align-items:flex-start;justify-content:space-between;min-height:56px;padding:8px 0}.sportline-breadcrumbs__content_w8cuQ{z-index:55;display:flex;flex-flow:row wrap;gap:4px;padding:0;margin:0;list-style:none}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sportline-breadcrumbs__inner": `sportline-breadcrumbs__inner_bX403`,
	"sportline-breadcrumbs__content": `sportline-breadcrumbs__content_w8cuQ`
};
export default ___CSS_LOADER_EXPORT___;
