// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.match-statistic-result-indicator_dnEBE{font-size:12px;font-weight:500;line-height:12px;letter-spacing:normal;display:flex;flex-direction:row;align-items:center;justify-content:center;width:20px;height:20px;padding:0;cursor:pointer;border-radius:16px}.match-statistic-result-indicator--unknown_MFVZk{color:var(--BrandText);background:var(--TextSecondary)}.match-statistic-result-indicator--win_DXfg8{color:var(--BrandText);background:var(--BrandDefault)}.match-statistic-result-indicator--lose_eYvwx{color:var(--BrandText);background:var(--ErrorDefault)}.match-statistic-result-indicator--draw_lnBrW{color:var(--BrandText);background:var(--AlertDefault)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"match-statistic-result-indicator": `match-statistic-result-indicator_dnEBE`,
	"match-statistic-result-indicator--unknown": `match-statistic-result-indicator--unknown_MFVZk`,
	"match-statistic-result-indicator--win": `match-statistic-result-indicator--win_DXfg8`,
	"match-statistic-result-indicator--lose": `match-statistic-result-indicator--lose_eYvwx`,
	"match-statistic-result-indicator--draw": `match-statistic-result-indicator--draw_lnBrW`
};
export default ___CSS_LOADER_EXPORT___;
