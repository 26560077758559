// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .gs-pitch-widget_ZXfKv{display:flex;align-items:flex-start;justify-content:center;width:100%;height:59.1715976331vw}html.app__layout--desktop .gs-pitch-widget__component_LPqvl{width:100%;height:204px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gs-pitch-widget": `gs-pitch-widget_ZXfKv`,
	"gs-pitch-widget__component": `gs-pitch-widget__component_LPqvl`
};
export default ___CSS_LOADER_EXPORT___;
