// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.post-match-statistic-heading_JSBLM{z-index:7;position:static;display:flex;align-items:center;justify-content:space-between;width:100%;height:56px;padding:0 0 0 8px}.post-match-statistic-heading__left_Vm7yL{display:flex;flex-direction:row-reverse;align-items:center;justify-content:space-between;height:100%;overflow:hidden;white-space:nowrap}.post-match-statistic-heading__left--expandable_uHv6b{cursor:pointer}.post-match-statistic-heading__icon_cmkYm{display:flex;width:16px;height:16px;margin-right:8px;color:var(--White)}.post-match-statistic-heading__title_rGZT8{position:relative;display:flex;align-items:center}.post-match-statistic-heading__label_qCMpE,.post-match-statistic-heading__title_rGZT8{font-size:18px;font-weight:500;line-height:20px;letter-spacing:normal;overflow:hidden;white-space:nowrap}.post-match-statistic-heading__label_qCMpE{width:fit-content;color:var(--TextDefault);text-overflow:ellipsis}.post-match-statistic-heading__right_bhU1V{font-size:13px;font-weight:500;line-height:16px;letter-spacing:normal;display:flex;flex-basis:auto;align-items:center;justify-content:flex-end;color:var(--TextSecondary);white-space:nowrap}.post-match-statistic-heading__expand-icon_WdMew{min-width:24px;color:var(--TextDefault);cursor:pointer}html.app__layout--desktop .post-match-statistic-heading_JSBLM{padding:0 0 0 16px}html.app__layout--desktop .post-match-statistic_rAtzA{padding:0}html.app__layout--desktop .post-match-statistic__headline_zCBSQ{margin-bottom:8px}html.app__layout--desktop .post-match-statistic__league-standings_i4FYe{margin:8px 0 0}html.app__layout--desktop .post-match-statistic-columns_Af0DI{display:flex;flex-direction:column;gap:8px}@media(min-width:1025px){html.app__layout--desktop .post-match-statistic-columns_Af0DI{flex-direction:row}html.app__layout--desktop .post-match-statistic-columns__left_DTd7V,html.app__layout--desktop .post-match-statistic-columns__right_vIWrD{flex-basis:50%}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"post-match-statistic-heading": `post-match-statistic-heading_JSBLM`,
	"post-match-statistic-heading__left": `post-match-statistic-heading__left_Vm7yL`,
	"post-match-statistic-heading__left--expandable": `post-match-statistic-heading__left--expandable_uHv6b`,
	"post-match-statistic-heading__icon": `post-match-statistic-heading__icon_cmkYm`,
	"post-match-statistic-heading__title": `post-match-statistic-heading__title_rGZT8`,
	"post-match-statistic-heading__label": `post-match-statistic-heading__label_qCMpE`,
	"post-match-statistic-heading__right": `post-match-statistic-heading__right_bhU1V`,
	"post-match-statistic-heading__expand-icon": `post-match-statistic-heading__expand-icon_WdMew`,
	"post-match-statistic": `post-match-statistic_rAtzA`,
	"post-match-statistic__headline": `post-match-statistic__headline_zCBSQ`,
	"post-match-statistic__league-standings": `post-match-statistic__league-standings_i4FYe`,
	"post-match-statistic-columns": `post-match-statistic-columns_Af0DI`,
	"post-match-statistic-columns__left": `post-match-statistic-columns__left_DTd7V`,
	"post-match-statistic-columns__right": `post-match-statistic-columns__right_vIWrD`
};
export default ___CSS_LOADER_EXPORT___;
