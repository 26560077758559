// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.league-progress-bar--default_u4hNP{color:var(--BasedOnSportTextDefault)}.league-progress-bar--colored_CotTZ{color:var(--BasedOnSportTextColored)}.league-progress-bar__time-label-wrapper_RXqq1{font-size:12px;font-weight:400;line-height:16px;letter-spacing:normal;display:flex;white-space:nowrap}.league-progress-bar__time-label-completed_WfR1y{text-align:right}.league-progress-bar__time-label_kPYUt{font-size:12px;font-weight:500;line-height:16px;letter-spacing:normal}.league-progress-bar__time-label-extra-info_cuvUz{font-size:12px;font-weight:400;line-height:16px;letter-spacing:normal}.league-progress-bar__bar_ApiiU{display:flex;justify-content:space-between;width:100%;margin:0 auto;padding:2px 0 4px}.league-progress-bar__filling_Q14Xv{position:relative;width:100%;height:2px}.league-progress-bar__dates_MhQBI{font-size:12px;font-weight:400;line-height:16px;letter-spacing:normal;display:flex;align-items:center;justify-content:space-between}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"league-progress-bar--default": `league-progress-bar--default_u4hNP`,
	"league-progress-bar--colored": `league-progress-bar--colored_CotTZ`,
	"league-progress-bar__time-label-wrapper": `league-progress-bar__time-label-wrapper_RXqq1`,
	"league-progress-bar__time-label-completed": `league-progress-bar__time-label-completed_WfR1y`,
	"league-progress-bar__time-label": `league-progress-bar__time-label_kPYUt`,
	"league-progress-bar__time-label-extra-info": `league-progress-bar__time-label-extra-info_cuvUz`,
	"league-progress-bar__bar": `league-progress-bar__bar_ApiiU`,
	"league-progress-bar__filling": `league-progress-bar__filling_Q14Xv`,
	"league-progress-bar__dates": `league-progress-bar__dates_MhQBI`
};
export default ___CSS_LOADER_EXPORT___;
