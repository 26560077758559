// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .sportline-event-content__navigation_yZM_O{width:100%;margin-bottom:12px}html.app__layout--desktop .sportline-event-content__breadcrumbs-holder_KBHl_{min-height:40px}html.app__layout--desktop .sportline-event-content__suspended-info_cBAtx{z-index:1;position:relative;margin:0;cursor:default}html.app__layout--desktop .sportline-event-content__post-match-statistic-headline_bgzSm{margin:0 0 8px}html.app__layout--desktop .sportline-event-content__statistic_zEP12:not(html.app__layout--desktop .sportline-event-content__statistic_zEP12:last-child){margin-bottom:4px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sportline-event-content__navigation": `sportline-event-content__navigation_yZM_O`,
	"sportline-event-content__breadcrumbs-holder": `sportline-event-content__breadcrumbs-holder_KBHl_`,
	"sportline-event-content__suspended-info": `sportline-event-content__suspended-info_cBAtx`,
	"sportline-event-content__post-match-statistic-headline": `sportline-event-content__post-match-statistic-headline_bgzSm`,
	"sportline-event-content__statistic": `sportline-event-content__statistic_zEP12`
};
export default ___CSS_LOADER_EXPORT___;
