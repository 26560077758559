import { defineComponent as _defineComponent } from 'vue';
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
import { toRef } from 'vue';
import VList from 'web/src/components/List/VList/VList.vue';
import VListItemAccordion from 'web/src/components/List/VListItemAccordion/VListItemAccordion.vue';
import { StatisticChartBarType } from 'web/src/modules/sportline/submodules/core-statistic/enums';
import StatisticChartBar from 'web/src/modules/sportline/submodules/core-statistic/components/StatisticChartBar.vue';
import LeagueMatchesStatisticBlock from './LeagueMatchesStatisticBlock.vue';
import { useLeagueMatchesStatistic } from './useLeagueMatchesStatistic';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LeagueMatchesStatistic',
    props: {
        leagueMatchesStatistic: {}
    },
    setup (__props) {
        const props = __props;
        const { leagueWinsStatistic, leagueWinsStatisticLabels, firstGoalTimeAverage, goalsTotal, goalsAverage, timeToGoal } = useLeagueMatchesStatistic({
            leagueMatchesStatistic: toRef(props, 'leagueMatchesStatistic')
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(VList, {
                "independent-items": ""
            }, {
                default: _withCtx(()=>[
                        _createVNode(VListItemAccordion, {
                            id: "league-matches-statistic",
                            title: _ctx.$t('WEB2_STATISTICS_OVERVIEW'),
                            "is-title-centered": "",
                            open: ""
                        }, {
                            content: _withCtx(()=>[
                                    _createElementVNode("div", {
                                        class: _normalizeClass(_ctx.$style['league-matches-statistic-content'])
                                    }, [
                                        _createElementVNode("div", {
                                            class: _normalizeClass(_ctx.$style['matches-total-wins'])
                                        }, [
                                            _createElementVNode("div", {
                                                class: _normalizeClass([
                                                    _ctx.$style['matches-total-wins__labels'],
                                                    _ctx.$style['statistic-chart-labels']
                                                ])
                                            }, [
                                                _createElementVNode("div", {
                                                    class: _normalizeClass(_ctx.$style['matches-total-wins__label-holder'])
                                                }, [
                                                    _createElementVNode("span", {
                                                        class: _normalizeClass([
                                                            _ctx.$style['statistic-chart-label-indicator'],
                                                            _ctx.$style['statistic-chart-label-indicator--home']
                                                        ])
                                                    }, null, 2),
                                                    _createElementVNode("span", {
                                                        class: _normalizeClass([
                                                            _ctx.$style['matches-total-wins__label'],
                                                            _ctx.$style['matches-total-wins__label--primary']
                                                        ])
                                                    }, _toDisplayString(_ctx.$t('WEB2_STATISTICS_HOME_TEAM')), 3),
                                                    _createElementVNode("span", {
                                                        class: _normalizeClass(_ctx.$style['matches-total-wins__label'])
                                                    }, _toDisplayString(_unref(leagueWinsStatisticLabels).home), 3)
                                                ], 2),
                                                _createElementVNode("div", {
                                                    class: _normalizeClass(_ctx.$style['matches-total-wins__label-holder'])
                                                }, [
                                                    _createElementVNode("span", {
                                                        class: _normalizeClass([
                                                            _ctx.$style['statistic-chart-label-indicator'],
                                                            _ctx.$style['statistic-chart-label-indicator--draw']
                                                        ])
                                                    }, null, 2),
                                                    _createElementVNode("span", {
                                                        class: _normalizeClass([
                                                            _ctx.$style['matches-total-wins__label'],
                                                            _ctx.$style['matches-total-wins__label--primary']
                                                        ])
                                                    }, _toDisplayString(_ctx.$t('WEB2_STATISTICS_DRAWS')), 3),
                                                    _createElementVNode("span", {
                                                        class: _normalizeClass(_ctx.$style['matches-total-wins__label'])
                                                    }, _toDisplayString(_unref(leagueWinsStatisticLabels).draw), 3)
                                                ], 2),
                                                _createElementVNode("div", {
                                                    class: _normalizeClass(_ctx.$style['matches-total-wins__label-holder'])
                                                }, [
                                                    _createElementVNode("span", {
                                                        class: _normalizeClass([
                                                            _ctx.$style['statistic-chart-label-indicator'],
                                                            _ctx.$style['statistic-chart-label-indicator--away']
                                                        ])
                                                    }, null, 2),
                                                    _createElementVNode("span", {
                                                        class: _normalizeClass([
                                                            _ctx.$style['matches-total-wins__label'],
                                                            _ctx.$style['matches-total-wins__label--primary']
                                                        ])
                                                    }, _toDisplayString(_ctx.$t('WEB2_STATISTICS_AWAY_TEAM')), 3),
                                                    _createElementVNode("span", {
                                                        class: _normalizeClass(_ctx.$style['matches-total-wins__label'])
                                                    }, _toDisplayString(_unref(leagueWinsStatisticLabels).away), 3)
                                                ], 2)
                                            ], 2),
                                            _createVNode(StatisticChartBar, {
                                                type: _unref(StatisticChartBarType).LABELED,
                                                "to-fixed": 1,
                                                "home-percentage": _unref(leagueWinsStatistic).homePercentage,
                                                "draw-percentage": _unref(leagueWinsStatistic).drawsPercentage,
                                                "away-percentage": _unref(leagueWinsStatistic).awayPercentage
                                            }, null, 8, [
                                                "type",
                                                "home-percentage",
                                                "draw-percentage",
                                                "away-percentage"
                                            ])
                                        ], 2),
                                        _createVNode(LeagueMatchesStatisticBlock, {
                                            title: _ctx.$t('WEB2_STATISTICS_TOTAL_GOALS'),
                                            "home-value": _unref(goalsTotal).homeValue,
                                            "overall-value": _unref(goalsTotal).overallValue,
                                            "away-value": _unref(goalsTotal).awayValue,
                                            "home-label": _unref(goalsTotal).homeLabel,
                                            "overall-label": _unref(goalsTotal).overallLabel,
                                            "away-label": _unref(goalsTotal).awayLabel,
                                            "do-use-progress-bar": ""
                                        }, null, 8, [
                                            "title",
                                            "home-value",
                                            "overall-value",
                                            "away-value",
                                            "home-label",
                                            "overall-label",
                                            "away-label"
                                        ]),
                                        _createVNode(LeagueMatchesStatisticBlock, {
                                            title: _ctx.$t('WEB2_STATISTICS_AVERAGE_MATCH_GOALS'),
                                            "home-value": _unref(goalsAverage).homeValue,
                                            "overall-value": _unref(goalsAverage).overallValue,
                                            "away-value": _unref(goalsAverage).awayValue,
                                            "home-label": _unref(goalsAverage).homeLabel,
                                            "overall-label": _unref(goalsAverage).overallLabel,
                                            "away-label": _unref(goalsAverage).awayLabel,
                                            "do-use-progress-bar": ""
                                        }, null, 8, [
                                            "title",
                                            "home-value",
                                            "overall-value",
                                            "away-value",
                                            "home-label",
                                            "overall-label",
                                            "away-label"
                                        ]),
                                        _createVNode(LeagueMatchesStatisticBlock, {
                                            title: _ctx.$t('WEB2_STATISTICS_FIRST_GOAL_AVERAGE_MIN'),
                                            "home-value": _unref(firstGoalTimeAverage).homeValue,
                                            "overall-value": _unref(firstGoalTimeAverage).overallValue,
                                            "away-value": _unref(firstGoalTimeAverage).awayValue,
                                            "home-label": _unref(firstGoalTimeAverage).homeLabel,
                                            "overall-label": _unref(firstGoalTimeAverage).overallLabel,
                                            "away-label": _unref(firstGoalTimeAverage).awayLabel
                                        }, null, 8, [
                                            "title",
                                            "home-value",
                                            "overall-value",
                                            "away-value",
                                            "home-label",
                                            "overall-label",
                                            "away-label"
                                        ]),
                                        _createVNode(LeagueMatchesStatisticBlock, {
                                            title: _ctx.$t('WEB2_STATISTICS_GOAL_AVERAGE_TIME'),
                                            "home-value": _unref(timeToGoal).homeValue,
                                            "overall-value": _unref(timeToGoal).overallValue,
                                            "away-value": _unref(timeToGoal).awayValue,
                                            "home-label": _unref(timeToGoal).homeLabel,
                                            "overall-label": _unref(timeToGoal).overallLabel,
                                            "away-label": _unref(timeToGoal).awayLabel
                                        }, null, 8, [
                                            "title",
                                            "home-value",
                                            "overall-value",
                                            "away-value",
                                            "home-label",
                                            "overall-label",
                                            "away-label"
                                        ])
                                    ], 2)
                                ]),
                            _: 1
                        }, 8, [
                            "title"
                        ])
                    ]),
                _: 1
            })), [
                [
                    _directive_auto_id,
                    'LeagueMatchesStatistic'
                ]
            ]);
        };
    }
});
