// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.statistic-colored-label_ZOQMs{display:inline-block;text-align:center;border-radius:2px}.statistic-colored-label--size-default_OCwFM{font-size:12px;font-weight:500;line-height:16px;letter-spacing:normal;padding:2px 4px}.statistic-colored-label--size-medium_kZrGa{font-size:12px;font-weight:500;line-height:16px;letter-spacing:normal;padding:3px 4px}.statistic-colored-label--type-transparent_x5fEV{color:var(--TextPrimary);background-color:#0000}.statistic-colored-label--type-default_jUJcW{color:var(--TextDefault);background-color:var(--Highlight)}.statistic-colored-label--type-home_Zdu5g{color:var(--BasedOnSportTextColored);background-color:var(--StatsA)}.statistic-colored-label--type-away_hxWvU{color:var(--BasedOnSportTextColored);background-color:var(--StatsB)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"statistic-colored-label": `statistic-colored-label_ZOQMs`,
	"statistic-colored-label--size-default": `statistic-colored-label--size-default_OCwFM`,
	"statistic-colored-label--size-medium": `statistic-colored-label--size-medium_kZrGa`,
	"statistic-colored-label--type-transparent": `statistic-colored-label--type-transparent_x5fEV`,
	"statistic-colored-label--type-default": `statistic-colored-label--type-default_jUJcW`,
	"statistic-colored-label--type-home": `statistic-colored-label--type-home_Zdu5g`,
	"statistic-colored-label--type-away": `statistic-colored-label--type-away_hxWvU`
};
export default ___CSS_LOADER_EXPORT___;
