import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
export default /*@__PURE__*/ _defineComponent({
    __name: 'NoActiveStreamWidget',
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['no-active-stream-widget'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['no-active-stream-icon-holder'])
                }, [
                    _createVNode(_unref(VIcon), {
                        size: _unref(IconSize).SIZE_40,
                        name: _unref(IconName).VIDEO_CAMERA
                    }, null, 8, [
                        "size",
                        "name"
                    ])
                ], 2),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['no-active-stream-description'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['no-active-stream-description__title'])
                    }, _toDisplayString(_ctx.$t('WEB2_STREAM_HAS_NOT_STARTED_YET_TITLE')), 3),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['no-active-stream-description__subtitle'])
                    }, _toDisplayString(_ctx.$t('WEB2_STREAM_HAS_NOT_STARTED_YET_DESCRIPTION')), 3)
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'NoActiveStreamWidget'
                ]
            ]);
        };
    }
});
