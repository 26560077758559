import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { toRef, computed, onBeforeMount } from 'vue';
import useRecalculateImageSource from 'web/src/components/Image/composables/useRecalculateImageSource';
import { getImageSourceFromRawImageData } from 'web/src/components/Image/utils';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PageContentHeadlineLayout',
    props: {
        backgroundColor: {},
        backgroundImage: {},
        doDisplayWidget: {
            type: Boolean
        },
        isFullWidthContent: {
            type: Boolean
        },
        isProportionalWidget: {
            type: Boolean
        }
    },
    setup (__props) {
        const props = __props;
        const backgroundColor = toRef(props, 'backgroundColor', null);
        const backgroundImage = toRef(props, 'backgroundImage', null);
        const imageSource = computed(()=>getImageSourceFromRawImageData(backgroundImage.value));
        const { normalizedSrc, normalizeSrc } = useRecalculateImageSource({
            imageSource
        });
        const layoutStyles = computed(()=>({
                backgroundColor: backgroundColor.value || void 0,
                backgroundImage: normalizedSrc.value ? `url('${normalizedSrc.value}')` : void 0
            }));
        onBeforeMount(normalizeSrc);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['page-content-headline-layout']]: true,
                    [_ctx.$style['page-content-headline-layout--default']]: !backgroundColor.value,
                    [_ctx.$style['page-content-headline-layout--colored']]: !!backgroundColor.value,
                    [_ctx.$style['page-content-headline-layout--backgrounded']]: !!backgroundImage.value
                }),
                style: _normalizeStyle(layoutStyles.value)
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass({
                        [_ctx.$style['page-content-headline-layout__content']]: true,
                        [_ctx.$style['page-content-headline-layout__content--strict-width']]: !_ctx.isFullWidthContent,
                        [_ctx.$style['page-content-headline-layout__content--proportional']]: _ctx.isProportionalWidget && _ctx.doDisplayWidget && !!_ctx.$slots.widget
                    })
                }, [
                    _renderSlot(_ctx.$slots, "content")
                ], 2),
                _ctx.doDisplayWidget && !!_ctx.$slots.widget ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass({
                        [_ctx.$style['page-content-headline-layout__widget']]: true,
                        [_ctx.$style['page-content-headline-layout__widget--proportional']]: _ctx.isProportionalWidget
                    })
                }, [
                    _renderSlot(_ctx.$slots, "widget")
                ], 2)) : _createCommentVNode("", true)
            ], 6)), [
                [
                    _directive_auto_id,
                    'PageContentHeadlineLayout'
                ]
            ]);
        };
    }
});
