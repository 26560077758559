// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-content-headline-layout_iZJzG{display:flex;overflow:hidden;border-radius:5px;transition:height .3s cubic-bezier(.4,0,.2,1)}.page-content-headline-layout--default_Y_o3r{color:var(--BasedOnSportTextDefault);background-color:var(--Layer1)}.page-content-headline-layout--colored_amHHu,.page-content-headline-layout--colored_amHHu h1,.page-content-headline-layout--colored_amHHu h2,.page-content-headline-layout--colored_amHHu h3,.page-content-headline-layout--colored_amHHu h4{color:var(--BasedOnSportTextColored)}.page-content-headline-layout--backgrounded_s1cwk{background-repeat:no-repeat;background-position:50%;background-size:cover}.page-content-headline-layout__content_ofsR5{flex-basis:100%;margin:0 auto}.page-content-headline-layout__content--strict-width_w3DvM{max-width:480px}.page-content-headline-layout__content--proportional_c0qAu,.page-content-headline-layout__widget--proportional_h_AIX{flex-basis:50%}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page-content-headline-layout": `page-content-headline-layout_iZJzG`,
	"page-content-headline-layout--default": `page-content-headline-layout--default_Y_o3r`,
	"page-content-headline-layout--colored": `page-content-headline-layout--colored_amHHu`,
	"page-content-headline-layout--backgrounded": `page-content-headline-layout--backgrounded_s1cwk`,
	"page-content-headline-layout__content": `page-content-headline-layout__content_ofsR5`,
	"page-content-headline-layout__content--strict-width": `page-content-headline-layout__content--strict-width_w3DvM`,
	"page-content-headline-layout__content--proportional": `page-content-headline-layout__content--proportional_c0qAu`,
	"page-content-headline-layout__widget--proportional": `page-content-headline-layout__widget--proportional_h_AIX`
};
export default ___CSS_LOADER_EXPORT___;
