// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.matches-statistic-competitors_geuHa{display:flex;justify-content:space-between;padding:14px 4px 18px}.matches-statistic-competitor_PrmGo{font-size:14px;font-weight:500;line-height:16px;text-transform:uppercase;letter-spacing:.25px;display:flex;justify-content:center;color:var(--TextDefault);text-transform:none}.matches-statistic-competitor--guest_V0jMC{text-align:right}.matches-statistic-competitor-logo_s36wx{width:20px;height:20px;margin:auto 0}.matches-statistic-competitor-name_eF9fg{line-height:20px}.matches-statistic-competitor-name_eF9fg:last-child{padding-left:8px}.matches-statistic-competitor-name_eF9fg:first-child{padding-right:8px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"matches-statistic-competitors": `matches-statistic-competitors_geuHa`,
	"matches-statistic-competitor": `matches-statistic-competitor_PrmGo`,
	"matches-statistic-competitor--guest": `matches-statistic-competitor--guest_V0jMC`,
	"matches-statistic-competitor-logo": `matches-statistic-competitor-logo_s36wx`,
	"matches-statistic-competitor-name": `matches-statistic-competitor-name_eF9fg`
};
export default ___CSS_LOADER_EXPORT___;
