import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, unref as _unref, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    key: 0
};
const _hoisted_2 = {
    key: 0
};
import { computed } from 'vue';
import StatisticColoredLabel from 'web/src/modules/sportline/submodules/core-statistic/components/StatisticColoredLabel.vue';
import StatisticChartBar from 'web/src/modules/sportline/submodules/core-statistic/components/StatisticChartBar.vue';
import { StatisticChartBarType, StatisticColoredLabelSize, StatisticColoredLabelType } from 'web/src/modules/sportline/submodules/core-statistic/enums';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LeagueMatchesStatisticBlock',
    props: {
        doUseProgressBar: {
            type: Boolean
        },
        title: {},
        homeLabel: {},
        overallLabel: {},
        awayLabel: {},
        homeValue: {},
        overallValue: {},
        awayValue: {}
    },
    setup (__props) {
        const props = __props;
        const overallLabelSize = computed(()=>props.doUseProgressBar ? StatisticColoredLabelSize.MEDIUM : StatisticColoredLabelSize.DEFAULT);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['league-matches-statistic-block'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass([
                        _ctx.$style['league-matches-statistic-block__item'],
                        _ctx.$style['league-matches-statistic-block-title']
                    ])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['league-matches-statistic-block-title__label'])
                    }, [
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['matches-statistic-block-title'])
                        }, _toDisplayString(_ctx.title), 3)
                    ], 2),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['league-matches-statistic-block-title__total-count'])
                    }, [
                        _ctx.overallLabel ? (_openBlock(), _createBlock(StatisticColoredLabel, {
                            key: 0,
                            label: _ctx.overallLabel,
                            size: overallLabelSize.value
                        }, null, 8, [
                            "label",
                            "size"
                        ])) : _createCommentVNode("", true)
                    ], 2)
                ], 2),
                _createElementVNode("div", {
                    class: _normalizeClass([
                        _ctx.$style['league-matches-statistic-block__item'],
                        _ctx.$style['league-matches-statistic-block-values']
                    ])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['league-matches-statistic-block-values__labels'])
                    }, [
                        _createElementVNode("div", {
                            class: _normalizeClass({
                                [_ctx.$style['league-matches-statistic-block-values__label']]: true,
                                [_ctx.$style['league-matches-statistic-block-values__label--tall']]: !_ctx.doUseProgressBar
                            })
                        }, [
                            _ctx.doUseProgressBar ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.homeLabel), 1)) : _ctx.homeLabel ? (_openBlock(), _createBlock(StatisticColoredLabel, {
                                key: 1,
                                label: _ctx.homeLabel,
                                size: _unref(StatisticColoredLabelSize).DEFAULT,
                                type: _unref(StatisticColoredLabelType).HOME
                            }, null, 8, [
                                "label",
                                "size",
                                "type"
                            ])) : _createCommentVNode("", true)
                        ], 2),
                        _createElementVNode("div", {
                            class: _normalizeClass({
                                [_ctx.$style['league-matches-statistic-block-values__label']]: true,
                                [_ctx.$style['league-matches-statistic-block-values__label--tall']]: !_ctx.doUseProgressBar
                            })
                        }, [
                            _createElementVNode("span", null, _toDisplayString(_ctx.$t('WEB2_STATISTICS_HOME_TEAM')) + " / " + _toDisplayString(_ctx.$t('WEB2_STATISTICS_AWAY_TEAM')), 1)
                        ], 2),
                        _createElementVNode("div", {
                            class: _normalizeClass({
                                [_ctx.$style['league-matches-statistic-block-values__label']]: true,
                                [_ctx.$style['league-matches-statistic-block-values__label--tall']]: !_ctx.doUseProgressBar
                            })
                        }, [
                            _ctx.doUseProgressBar ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.awayLabel), 1)) : _ctx.awayLabel ? (_openBlock(), _createBlock(StatisticColoredLabel, {
                                key: 1,
                                label: _ctx.awayLabel,
                                size: _unref(StatisticColoredLabelSize).DEFAULT,
                                type: _unref(StatisticColoredLabelType).AWAY
                            }, null, 8, [
                                "label",
                                "size",
                                "type"
                            ])) : _createCommentVNode("", true)
                        ], 2)
                    ], 2),
                    _ctx.doUseProgressBar ? (_openBlock(), _createBlock(StatisticChartBar, {
                        key: 0,
                        type: _unref(StatisticChartBarType).NARROW,
                        "home-percentage": _ctx.homeValue,
                        "away-percentage": _ctx.awayValue
                    }, null, 8, [
                        "type",
                        "home-percentage",
                        "away-percentage"
                    ])) : _createCommentVNode("", true)
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'LeagueMatchesStatisticBlock'
                ]
            ]);
        };
    }
});
