// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.league-playoff__content_l2nPZ{display:grid;grid-template-columns:1fr;gap:8px}.league-playoff__title_yBsRR{font-size:14px;font-weight:500;line-height:16px;letter-spacing:.25px;box-sizing:border-box;width:100%;padding:14px 16px;color:var(--TextDefault);border:1px solid var(--Highlight);border-radius:5px}.league-playoff__rounds_fj3AE{position:relative;display:flex;align-items:stretch;justify-content:flex-start;width:100%;padding:8px 0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"league-playoff__content": `league-playoff__content_l2nPZ`,
	"league-playoff__title": `league-playoff__title_yBsRR`,
	"league-playoff__rounds": `league-playoff__rounds_fj3AE`
};
export default ___CSS_LOADER_EXPORT___;
