// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.teams-confrontation-statistic-block_LmlYu{font-size:12px;font-weight:500;line-height:12px;letter-spacing:normal;padding:4px;color:var(--TextPrimary);background-color:var(--Layer2);border-radius:5px}.teams-confrontation-statistic-block__charts_WG8Ob,.teams-confrontation-statistic-block__heading_nqg4L{display:flex;flex-wrap:nowrap;justify-content:flex-start;margin:4px}.teams-confrontation-statistic-block__charts_WG8Ob{gap:4px}.chart-heading_xZnaV{flex-grow:1;text-align:center}.chart-heading--left_U9upP{text-align:left}.chart-heading--right_rpZ5L{text-align:right}.chart-bar_k9fW0{position:relative;display:flex;flex-direction:row;flex-grow:1;height:4px;overflow:hidden;background-color:var(--Highlight);border-radius:2px}.chart-bar--left__DaEx{flex-direction:row-reverse}.chart-bar__filling_MWtBu{display:block;height:100%}.chart-bar__filling--left_TXt7d{background-color:var(--StatsA)}.chart-bar__filling--right_kqKV3{background-color:var(--StatsB)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"teams-confrontation-statistic-block": `teams-confrontation-statistic-block_LmlYu`,
	"teams-confrontation-statistic-block__charts": `teams-confrontation-statistic-block__charts_WG8Ob`,
	"teams-confrontation-statistic-block__heading": `teams-confrontation-statistic-block__heading_nqg4L`,
	"chart-heading": `chart-heading_xZnaV`,
	"chart-heading--left": `chart-heading--left_U9upP`,
	"chart-heading--right": `chart-heading--right_rpZ5L`,
	"chart-bar": `chart-bar_k9fW0`,
	"chart-bar--left": `chart-bar--left__DaEx`,
	"chart-bar__filling": `chart-bar__filling_MWtBu`,
	"chart-bar__filling--left": `chart-bar__filling--left_TXt7d`,
	"chart-bar__filling--right": `chart-bar__filling--right_kqKV3`
};
export default ___CSS_LOADER_EXPORT___;
