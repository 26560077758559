// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .floating-widget-box_CZ9R0{position:relative;overflow:hidden;background-color:var(--Layer1);transition:.3s ease-out;width:325px;height:100%;max-height:200px;border-radius:inherit}html.app__layout--desktop .floating-widget-box--full-size_h_9e6{width:100%;height:100%;max-height:100%}html.app__layout--desktop .floating-widget-box--fixed_eZ9lb{z-index:5;position:fixed;bottom:10px;left:10px;width:285px;height:176px;border-radius:5px}html.app__layout--desktop .floating-widget-box__icon-close_DXOWh{z-index:5;position:absolute;top:4px;right:4px;background-color:hsla(var(--huecolorprimary),var(--saturationcolorprimary),calc(var(--lightnesscolorprimary) - 7%),.7)}html.app__layout--desktop .floating-widget-box__wrapper_WzkeU{position:relative}html.app__layout--desktop .floating-widget-box__wrapper--full-size_q_vcO{width:100%;height:100%;max-height:100%}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"floating-widget-box": `floating-widget-box_CZ9R0`,
	"floating-widget-box--full-size": `floating-widget-box--full-size_h_9e6`,
	"floating-widget-box--fixed": `floating-widget-box--fixed_eZ9lb`,
	"floating-widget-box__icon-close": `floating-widget-box__icon-close_DXOWh`,
	"floating-widget-box__wrapper": `floating-widget-box__wrapper_WzkeU`,
	"floating-widget-box__wrapper--full-size": `floating-widget-box__wrapper--full-size_q_vcO`
};
export default ___CSS_LOADER_EXPORT___;
