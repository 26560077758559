import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { toRef } from 'vue';
import StatisticTeamLogo from 'web/src/modules/sportline/submodules/event-statistic/components/StatisticTeamLogo.vue';
import usePostMatchStatisticSimpleResultScore from './usePostMatchStatisticSimpleResultScore';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PostMatchStatisticSimpleResultScore',
    props: {
        postMatchStatistic: {},
        isDisplayScoresByTimes: {
            type: Boolean
        },
        isDisplayScoresBySets: {
            type: Boolean
        }
    },
    setup (__props) {
        const props = __props;
        const postMatchStatistic = toRef(props, 'postMatchStatistic');
        const isDisplayScoresByTimes = toRef(props, 'isDisplayScoresByTimes', false);
        const isDisplayScoresBySets = toRef(props, 'isDisplayScoresBySets', false);
        const { teams, resultScore, scoresDetails, overtimeScores } = usePostMatchStatisticSimpleResultScore({
            postMatchStatistic,
            isDisplayScoresByTimes,
            isDisplayScoresBySets
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _unref(teams) ? _withDirectives((_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(_ctx.$style['post-match-statistic-simple-result-score'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['post-match-statistic-simple-result-score__teams'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['post-match-statistic-simple-result-score__team'])
                    }, [
                        _createVNode(StatisticTeamLogo, {
                            class: _normalizeClass(_ctx.$style['post-match-statistic-simple-result-score__team-logo']),
                            team: _unref(teams)[0]
                        }, null, 8, [
                            "class",
                            "team"
                        ]),
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['post-match-statistic-simple-result-score__team-name'])
                        }, _toDisplayString(_unref(teams)[0].name), 3)
                    ], 2),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['post-match-statistic-simple-result-score__team'])
                    }, [
                        _createVNode(StatisticTeamLogo, {
                            class: _normalizeClass(_ctx.$style['post-match-statistic-simple-result-score__team-logo']),
                            team: _unref(teams)[1]
                        }, null, 8, [
                            "class",
                            "team"
                        ]),
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['post-match-statistic-simple-result-score__team-name'])
                        }, _toDisplayString(_unref(teams)[1].name), 3)
                    ], 2)
                ], 2),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['post-match-statistic-simple-result-score__right'])
                }, [
                    isDisplayScoresBySets.value ? (_openBlock(true), _createElementBlock(_Fragment, {
                        key: 0
                    }, _renderList(_unref(scoresDetails), (score, index)=>(_openBlock(), _createElementBlock("div", {
                            key: `by-sets-${index}`,
                            class: _normalizeClass(_ctx.$style['post-match-statistic-simple-result-score__scores'])
                        }, [
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['post-match-statistic-simple-result-score__score'])
                            }, _toDisplayString(score[0]), 3),
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['post-match-statistic-simple-result-score__score'])
                            }, _toDisplayString(score[1]), 3)
                        ], 2))), 128)) : _createCommentVNode("", true),
                    _unref(resultScore) ? (_openBlock(), _createElementBlock("div", {
                        key: 1,
                        class: _normalizeClass([
                            _ctx.$style['post-match-statistic-simple-result-score__scores'],
                            _ctx.$style['post-match-statistic-simple-result-score__scores--final']
                        ])
                    }, [
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['post-match-statistic-simple-result-score__score'])
                        }, _toDisplayString(_unref(resultScore)[0]), 3),
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['post-match-statistic-simple-result-score__score'])
                        }, _toDisplayString(_unref(resultScore)[1]), 3)
                    ], 2)) : _createCommentVNode("", true),
                    isDisplayScoresByTimes.value ? (_openBlock(), _createElementBlock(_Fragment, {
                        key: 2
                    }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(scoresDetails), (score, index)=>(_openBlock(), _createElementBlock("div", {
                                key: `by-times-${index}`,
                                class: _normalizeClass(_ctx.$style['post-match-statistic-simple-result-score__scores'])
                            }, [
                                _createElementVNode("div", {
                                    class: _normalizeClass(_ctx.$style['post-match-statistic-simple-result-score__score'])
                                }, _toDisplayString(score[0]), 3),
                                _createElementVNode("div", {
                                    class: _normalizeClass(_ctx.$style['post-match-statistic-simple-result-score__score'])
                                }, _toDisplayString(score[1]), 3)
                            ], 2))), 128)),
                        _unref(overtimeScores) ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: _normalizeClass(_ctx.$style['post-match-statistic-simple-result-score__scores'])
                        }, [
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['post-match-statistic-simple-result-score__score'])
                            }, _toDisplayString(_unref(overtimeScores)[0]), 3),
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['post-match-statistic-simple-result-score__score'])
                            }, _toDisplayString(_unref(overtimeScores)[1]), 3)
                        ], 2)) : _createCommentVNode("", true)
                    ], 64)) : _createCommentVNode("", true)
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'PostMatchStatisticSimpleResultScore'
                ]
            ]) : _createCommentVNode("", true);
        };
    }
});
