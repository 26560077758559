// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.no-events-content_ZG7RX{display:flex;flex-direction:column;align-items:center;padding:32px 24px;text-align:center;background-color:var(--Layer1);border-radius:5px}.no-events-content__label_Mw_v9{font-size:14px;font-weight:400;line-height:24px;letter-spacing:.25px;width:100%;margin:0;color:var(--TextPrimary);text-align:center}html.app__layout--desktop .sport-event-league_kD4am{position:relative;padding:0}html.app__layout--desktop .sport-event-league--extra-paddings_BfSZy{padding:0}html.app__layout--desktop .sport-event-league__content_Vvp81{position:relative}html.app__layout--desktop .sport-event-league__headline_ox2pi{margin:8px 0}html.app__layout--desktop .sport-event-league__headline--extra-paddings_UxXnF{margin:16px 0 8px}html.app__layout--desktop .sport-event-league__navigation_tKtLN{width:100%;margin-left:0;margin-bottom:8px}html.app__layout--desktop .sport-event-league__redirect-info_TU2O9{margin-top:8px}html.app__layout--desktop .sport-event-league__no-events_Rbe4t{margin-top:8px}html.app__layout--desktop .sport-event-league__content-title_fv6zG{font-size:18px;font-weight:500;line-height:20px;letter-spacing:normal;padding:18px 0 18px 16px;color:var(--TextDefault)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"no-events-content": `no-events-content_ZG7RX`,
	"no-events-content__label": `no-events-content__label_Mw_v9`,
	"sport-event-league": `sport-event-league_kD4am`,
	"sport-event-league--extra-paddings": `sport-event-league--extra-paddings_BfSZy`,
	"sport-event-league__content": `sport-event-league__content_Vvp81`,
	"sport-event-league__headline": `sport-event-league__headline_ox2pi`,
	"sport-event-league__headline--extra-paddings": `sport-event-league__headline--extra-paddings_UxXnF`,
	"sport-event-league__navigation": `sport-event-league__navigation_tKtLN`,
	"sport-event-league__redirect-info": `sport-event-league__redirect-info_TU2O9`,
	"sport-event-league__no-events": `sport-event-league__no-events_Rbe4t`,
	"sport-event-league__content-title": `sport-event-league__content-title_fv6zG`
};
export default ___CSS_LOADER_EXPORT___;
