import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
export default /*@__PURE__*/ _defineComponent({
    __name: 'TeamsConfrontationStatisticBlock',
    props: {
        confrontationBlock: {}
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['teams-confrontation-statistic-block'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['teams-confrontation-statistic-block__heading'])
                }, [
                    _createElementVNode("span", {
                        class: _normalizeClass([
                            _ctx.$style['chart-heading'],
                            _ctx.$style['chart-heading--left']
                        ])
                    }, _toDisplayString(_ctx.confrontationBlock.host.value), 3),
                    _createElementVNode("span", {
                        class: _normalizeClass(_ctx.$style['chart-heading'])
                    }, _toDisplayString(_ctx.confrontationBlock.name), 3),
                    _createElementVNode("span", {
                        class: _normalizeClass([
                            _ctx.$style['chart-heading'],
                            _ctx.$style['chart-heading--right']
                        ])
                    }, _toDisplayString(_ctx.confrontationBlock.guest.value), 3)
                ], 2),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['teams-confrontation-statistic-block__charts'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass([
                            _ctx.$style['chart-bar'],
                            _ctx.$style['chart-bar--left']
                        ])
                    }, [
                        _createElementVNode("div", {
                            class: _normalizeClass([
                                _ctx.$style['chart-bar__filling'],
                                _ctx.$style['chart-bar__filling--left']
                            ]),
                            style: _normalizeStyle({
                                width: `${_ctx.confrontationBlock.host.percent}%`
                            })
                        }, null, 6)
                    ], 2),
                    _createElementVNode("div", {
                        class: _normalizeClass([
                            _ctx.$style['chart-bar'],
                            _ctx.$style['chart-bar--right']
                        ])
                    }, [
                        _createElementVNode("div", {
                            class: _normalizeClass([
                                _ctx.$style['chart-bar__filling'],
                                _ctx.$style['chart-bar__filling--right']
                            ]),
                            style: _normalizeStyle({
                                width: `${_ctx.confrontationBlock.guest.percent}%`
                            })
                        }, null, 6)
                    ], 2)
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'TeamsConfrontationStatisticBlock'
                ]
            ]);
        };
    }
});
