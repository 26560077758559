// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.league-matches-statistic-block_LnD7M{padding:0 8px;background-color:var(--Layer2);border-radius:5px}.league-matches-statistic-block__item_GnHTB{padding:12px 0}.league-matches-statistic-block__item_GnHTB:not(:last-child){border-bottom:1px solid var(--Layer1)}.league-matches-statistic-block-title_aBB1c{display:grid;grid-template-columns:1fr 60px;column-gap:8px}.league-matches-statistic-block-title__label_eMbqY{text-align:left}.league-matches-statistic-block-title__total-count_ROkUS{text-align:right}.league-matches-statistic-block-values_OTnjE{display:grid;grid-template-columns:1fr;gap:8px}.league-matches-statistic-block-values__labels_mEU2b{display:grid;grid-template-columns:60px 1fr 60px;column-gap:8px}.league-matches-statistic-block-values__label_CDbIO{font-size:13px;font-weight:500;line-height:16px;letter-spacing:normal;color:var(--TextPrimary);text-align:center;vertical-align:middle}.league-matches-statistic-block-values__label--tall_rYAIu{line-height:20px}.league-matches-statistic-block-values__label_CDbIO:first-child{text-align:left}.league-matches-statistic-block-values__label_CDbIO:last-child{text-align:right}.matches-statistic-block-title_SPdHA{font-size:14px;font-weight:500;line-height:16px;letter-spacing:.25px;display:inline-block;color:var(--TextDefault)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"league-matches-statistic-block": `league-matches-statistic-block_LnD7M`,
	"league-matches-statistic-block__item": `league-matches-statistic-block__item_GnHTB`,
	"league-matches-statistic-block-title": `league-matches-statistic-block-title_aBB1c`,
	"league-matches-statistic-block-title__label": `league-matches-statistic-block-title__label_eMbqY`,
	"league-matches-statistic-block-title__total-count": `league-matches-statistic-block-title__total-count_ROkUS`,
	"league-matches-statistic-block-values": `league-matches-statistic-block-values_OTnjE`,
	"league-matches-statistic-block-values__labels": `league-matches-statistic-block-values__labels_mEU2b`,
	"league-matches-statistic-block-values__label": `league-matches-statistic-block-values__label_CDbIO`,
	"league-matches-statistic-block-values__label--tall": `league-matches-statistic-block-values__label--tall_rYAIu`,
	"matches-statistic-block-title": `matches-statistic-block-title_SPdHA`
};
export default ___CSS_LOADER_EXPORT___;
