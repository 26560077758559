// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .live-stream-slide_PuoQh{display:flex;align-items:stretch;justify-content:center;width:100%;height:100%}html.app__layout--desktop .live-stream-slide__content_npgnn{width:100%}html.app__layout--desktop .live-stream-slide__error_xDsVb{display:flex;flex-direction:column;justify-content:center;width:100%;padding:0 16px}html.app__layout--desktop .live-stream-slide__loader_wba8k{display:flex;flex-direction:column;align-items:center;justify-content:center;width:100%}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"live-stream-slide": `live-stream-slide_PuoQh`,
	"live-stream-slide__content": `live-stream-slide__content_npgnn`,
	"live-stream-slide__error": `live-stream-slide__error_xDsVb`,
	"live-stream-slide__loader": `live-stream-slide__loader_wba8k`
};
export default ___CSS_LOADER_EXPORT___;
