// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sportradar-statistics_pvDeK{min-height:100vh}.sportradar-statistics__title_NqyHp{font-size:18px;font-weight:500;line-height:20px;letter-spacing:normal;padding:16px;margin:0;color:var(--TextDefault)}.sportradar-statistics__loader_yuSxo{position:absolute;top:0;right:0;left:0;display:flex;align-items:center;justify-content:center;height:453px;padding:50px 0;background:var(--Layer0)}.sportradar-statistics__loader-image_YnXd1{max-width:255px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sportradar-statistics": `sportradar-statistics_pvDeK`,
	"sportradar-statistics__title": `sportradar-statistics__title_NqyHp`,
	"sportradar-statistics__loader": `sportradar-statistics__loader_yuSxo`,
	"sportradar-statistics__loader-image": `sportradar-statistics__loader-image_YnXd1`
};
export default ___CSS_LOADER_EXPORT___;
