// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.headline-team_MtefL{font-size:14px;font-weight:500;line-height:16px;letter-spacing:.25px}.headline-team--with-logo_q6_xK{display:flex;flex-direction:column;align-items:center;width:100%}.headline-team__container_ybYGT{display:flex;align-items:center;width:50%}.headline-team__container--center_nBRfE{justify-content:center;width:100%}.headline-team__container--left_pe3DN{justify-content:flex-end;text-align:right}.headline-team__container--right_WcYMT{flex-direction:row-reverse;justify-content:flex-end;text-align:left}.headline-team-logo_jxTAY{width:40px;height:40px;border-radius:50%}.headline-team-logo--large_WNMGw{width:50px;height:50px}.headline-team-logo__container_Y04AU{display:flex;align-items:center;justify-content:center;width:60px;height:60px;margin-bottom:16px;background-color:var(--White);border-radius:50%}.headline-team-name_oTNNZ{font-size:14px;font-weight:500;line-height:16px;letter-spacing:.25px;display:flex;gap:4px}.headline-team-name--host_hv67x{justify-content:flex-end;text-align:right}.headline-team-name--guest_vY3Ph{flex-direction:row-reverse;justify-content:flex-end;text-align:left}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headline-team": `headline-team_MtefL`,
	"headline-team--with-logo": `headline-team--with-logo_q6_xK`,
	"headline-team__container": `headline-team__container_ybYGT`,
	"headline-team__container--center": `headline-team__container--center_nBRfE`,
	"headline-team__container--left": `headline-team__container--left_pe3DN`,
	"headline-team__container--right": `headline-team__container--right_WcYMT`,
	"headline-team-logo": `headline-team-logo_jxTAY`,
	"headline-team-logo--large": `headline-team-logo--large_WNMGw`,
	"headline-team-logo__container": `headline-team-logo__container_Y04AU`,
	"headline-team-name": `headline-team-name_oTNNZ`,
	"headline-team-name--host": `headline-team-name--host_hv67x`,
	"headline-team-name--guest": `headline-team-name--guest_vY3Ph`
};
export default ___CSS_LOADER_EXPORT___;
