// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.post-match-statistic-incident-list-item_uXELh{display:flex}.post-match-statistic-incident-list-item--host_ycCRM{justify-content:left;text-align:left}.post-match-statistic-incident-list-item--guest_oPSUd{flex-direction:row-reverse;justify-content:right;text-align:right}.post-match-statistic-incident-goal-mark_wNdEu{display:flex;justify-content:center;height:16px;padding:2px 4px 2px 2px;margin:0 8px;background-color:var(--BrandDefault);border-radius:20px}.post-match-statistic-incident-goal-mark--disabled-goal_exmty,.post-match-statistic-incident-goal-mark--own-goal_gk0g5{background-color:var(--ErrorDefault)}.post-match-statistic-incident-card-mark_lhyUp,.post-match-statistic-incident-goal-mark__part_UQlHC{display:flex;flex-direction:column;justify-content:center;vertical-align:middle}.post-match-statistic-incident-card-mark_lhyUp{height:16px;margin:0 8px}.post-match-statistic-incident-card-icon_dB7BI{width:16px;height:16px}.post-match-statistic-incident-goal-icon_NIoOs{width:12px;height:12px;fill:var(--BrandText)}.post-match-statistic-incident-team-logo_RE6M9{width:16px;height:16px}.post-match-statistic-incident-goal-label_S3t8r{font-size:11px;font-weight:500;line-height:16px;text-transform:uppercase;letter-spacing:-.2px;padding-left:4px;color:var(--BrandText)}.post-match-statistic-incident-time_TAuzV{font-size:13px;font-weight:500;line-height:16px;letter-spacing:normal;width:40px;min-width:40px;color:var(--TextSecondary)}.post-match-statistic-incident-time--guest_HBEYX{text-align:right}.post-match-statistic-incident-player_H8S6p{font-size:13px;font-weight:500;line-height:16px;letter-spacing:normal;color:var(--TextDefault)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"post-match-statistic-incident-list-item": `post-match-statistic-incident-list-item_uXELh`,
	"post-match-statistic-incident-list-item--host": `post-match-statistic-incident-list-item--host_ycCRM`,
	"post-match-statistic-incident-list-item--guest": `post-match-statistic-incident-list-item--guest_oPSUd`,
	"post-match-statistic-incident-goal-mark": `post-match-statistic-incident-goal-mark_wNdEu`,
	"post-match-statistic-incident-goal-mark--disabled-goal": `post-match-statistic-incident-goal-mark--disabled-goal_exmty`,
	"post-match-statistic-incident-goal-mark--own-goal": `post-match-statistic-incident-goal-mark--own-goal_gk0g5`,
	"post-match-statistic-incident-card-mark": `post-match-statistic-incident-card-mark_lhyUp`,
	"post-match-statistic-incident-goal-mark__part": `post-match-statistic-incident-goal-mark__part_UQlHC`,
	"post-match-statistic-incident-card-icon": `post-match-statistic-incident-card-icon_dB7BI`,
	"post-match-statistic-incident-goal-icon": `post-match-statistic-incident-goal-icon_NIoOs`,
	"post-match-statistic-incident-team-logo": `post-match-statistic-incident-team-logo_RE6M9`,
	"post-match-statistic-incident-goal-label": `post-match-statistic-incident-goal-label_S3t8r`,
	"post-match-statistic-incident-time": `post-match-statistic-incident-time_TAuzV`,
	"post-match-statistic-incident-time--guest": `post-match-statistic-incident-time--guest_HBEYX`,
	"post-match-statistic-incident-player": `post-match-statistic-incident-player_H8S6p`
};
export default ___CSS_LOADER_EXPORT___;
