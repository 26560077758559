// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.no-events-content_asxGQ{display:flex;flex-direction:column;align-items:center;padding:32px 24px;text-align:center;background-color:var(--Layer1);border-radius:5px}.no-events-content__label_KrYUK{font-size:14px;font-weight:400;line-height:24px;letter-spacing:.25px;width:100%;margin:0;color:var(--TextPrimary);text-align:center}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"no-events-content": `no-events-content_asxGQ`,
	"no-events-content__label": `no-events-content__label_KrYUK`
};
export default ___CSS_LOADER_EXPORT___;
