// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .component-wrapper_DwAAz,html.app__layout--desktop .sport-event-details_mTq18{display:block;flex-direction:column}html.app__layout--desktop .sport-event-details_mTq18{flex:1 0 100%;height:100%}html.app__layout--desktop .sport-event-details__related_Br5ek{flex:1 0 100%}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"component-wrapper": `component-wrapper_DwAAz`,
	"sport-event-details": `sport-event-details_mTq18`,
	"sport-event-details__related": `sport-event-details__related_Br5ek`
};
export default ___CSS_LOADER_EXPORT___;
