import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    key: 0
};
const _hoisted_2 = {
    key: 1
};
const _hoisted_3 = {
    key: 2
};
const _hoisted_4 = {
    key: 3
};
import { toRef } from 'vue';
import VList from 'web/src/components/List/VList/VList.vue';
import VListItemAccordion from 'web/src/components/List/VListItemAccordion/VListItemAccordion.vue';
import usePostMatchStatisticResults from './usePostMatchStatisticResults';
import PostMatchStatisticTimeline from './PostMatchStatisticTimeline.vue';
import PostMatchStatisticSimpleResultScore from './PostMatchStatisticSimpleResultScore.vue';
import PostMatchStatisticIncidentsList from './PostMatchStatisticIncidentsList.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PostMatchStatisticResults',
    props: {
        postMatchStatistic: {}
    },
    setup (__props) {
        const props = __props;
        const postMatchStatistic = toRef(props, 'postMatchStatistic');
        const { isDisplayTimeline, isDisplayScoresByTimes, isDisplayScoresBySets } = usePostMatchStatisticResults({
            postMatchStatistic
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(VList, {
                "independent-items": ""
            }, {
                default: _withCtx(()=>[
                        _createVNode(VListItemAccordion, {
                            id: "results-of-the-match",
                            title: _ctx.$t('WEB2_MATCH_STATISTIC_MATCH_RESULTS'),
                            "is-title-centered": "",
                            open: ""
                        }, {
                            content: _withCtx(()=>[
                                    _unref(isDisplayTimeline) && postMatchStatistic.value ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                                        _createVNode(PostMatchStatisticTimeline, {
                                            class: _normalizeClass(_ctx.$style['post-match-statistic-results__timeline']),
                                            "post-match-statistic": postMatchStatistic.value
                                        }, null, 8, [
                                            "class",
                                            "post-match-statistic"
                                        ]),
                                        _createVNode(PostMatchStatisticIncidentsList, {
                                            "post-match-statistic": postMatchStatistic.value
                                        }, null, 8, [
                                            "post-match-statistic"
                                        ])
                                    ])) : _unref(isDisplayScoresByTimes) && postMatchStatistic.value ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                                        _createVNode(PostMatchStatisticSimpleResultScore, {
                                            "post-match-statistic": postMatchStatistic.value,
                                            "is-display-scores-by-times": ""
                                        }, null, 8, [
                                            "post-match-statistic"
                                        ])
                                    ])) : _unref(isDisplayScoresBySets) && postMatchStatistic.value ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                                        _createVNode(PostMatchStatisticSimpleResultScore, {
                                            "post-match-statistic": postMatchStatistic.value,
                                            "is-display-scores-by-sets": ""
                                        }, null, 8, [
                                            "post-match-statistic"
                                        ])
                                    ])) : postMatchStatistic.value ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                                        _createVNode(PostMatchStatisticSimpleResultScore, {
                                            "post-match-statistic": postMatchStatistic.value
                                        }, null, 8, [
                                            "post-match-statistic"
                                        ])
                                    ])) : _createCommentVNode("", true)
                                ]),
                            _: 1
                        }, 8, [
                            "title"
                        ])
                    ]),
                _: 1
            })), [
                [
                    _directive_auto_id,
                    'PostMatchStatisticResults'
                ]
            ]);
        };
    }
});
