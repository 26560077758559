import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    key: 1
};
import { toRef } from 'vue';
import StatisticTeamLogo from 'web/src/modules/sportline/submodules/event-statistic/components/StatisticTeamLogo.vue';
import { PopperApplyTarget, PopperPosition } from 'web/src/components/Popper/VPopper/enums';
import VPopper from 'web/src/components/Popper/VPopper/VPopper.vue';
import { useLeaguePlayoffMatchesBlock } from './useLeaguePlayoffMatchesBlock';
import LeaguePlayoffMatchesBlockDetalis from './LeaguePlayoffMatchesBlockDetalis.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LeaguePlayoffMatchesBlock',
    props: {
        block: {}
    },
    setup (__props) {
        const props = __props;
        const block = toRef(props, 'block');
        const { matchScores, participants } = useLeaguePlayoffMatchesBlock({
            block
        });
        const popperModifiers = [
            {
                name: 'offset',
                options: {
                    offset: (param)=>{
                        let { popper } = param;
                        return [
                            -1,
                            -popper.height + 1
                        ];
                    }
                }
            }
        ];
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['league-playoff-matches-block'])
            }, [
                _createVNode(VPopper, {
                    position: _unref(PopperPosition).TOP_START,
                    "has-offset": false,
                    "apply-target": _unref(PopperApplyTarget).Body,
                    "is-full-width": "",
                    modifiers: popperModifiers
                }, {
                    reference: _withCtx(()=>[
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['league-playoff-matches-block__matches'])
                            }, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(participants), (participant, participantIndex)=>(_openBlock(), _createElementBlock("div", {
                                        key: participantIndex,
                                        class: _normalizeClass({
                                            [_ctx.$style['league-playoff-matches-block__match match-block-team']]: true,
                                            [_ctx.$style['league-playoff-matches-block__match--winner']]: participant.winner
                                        })
                                    }, [
                                        participant.team ? (_openBlock(), _createElementBlock(_Fragment, {
                                            key: 0
                                        }, [
                                            _createElementVNode("div", {
                                                class: _normalizeClass(_ctx.$style['match-block-team__identity'])
                                            }, [
                                                _createVNode(StatisticTeamLogo, {
                                                    class: _normalizeClass(_ctx.$style['match-block-team__logo']),
                                                    team: participant.team
                                                }, null, 8, [
                                                    "class",
                                                    "team"
                                                ]),
                                                _createElementVNode("div", {
                                                    class: _normalizeClass(_ctx.$style['match-block-team__name'])
                                                }, _toDisplayString(participant.team.name), 3)
                                            ], 2),
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(matchScores), (scores, scoresKey)=>(_openBlock(), _createElementBlock("div", {
                                                    key: scoresKey,
                                                    class: _normalizeClass(_ctx.$style['match-block-team__result-score'])
                                                }, _toDisplayString(participant.team.id ? scores[participant.team.id] || '-' : '-'), 3))), 128))
                                        ], 64)) : _createCommentVNode("", true)
                                    ], 2))), 128))
                            ], 2)
                        ]),
                    default: _withCtx((param)=>{
                        let { shown, hide } = param;
                        return [
                            shown ? (_openBlock(), _createBlock(LeaguePlayoffMatchesBlockDetalis, {
                                key: 0,
                                block: block.value,
                                onRequireClose: hide
                            }, null, 8, [
                                "block",
                                "onRequireClose"
                            ])) : (_openBlock(), _createElementBlock("div", _hoisted_1))
                        ];
                    }),
                    _: 1
                }, 8, [
                    "position",
                    "apply-target"
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'LeaguePlayoffMatchesBlock'
                ]
            ]);
        };
    }
});
