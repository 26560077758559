// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.statistic-timeline-incident-icon--card-yellow_nJSfH{fill:var(--AlertDefault)}.statistic-timeline-incident-icon--card-red_vELCq{fill:var(--ErrorDefault)}.statistic-timeline-incident-icon--goal_U_JLn{fill:var(--TextSecondary)}.statistic-timeline-incident-icon--disabled-goal_2kvnI,.statistic-timeline-incident-icon--own-goal_nhqEi{fill:var(--ErrorDefault)}.statistic-timeline-incident-icon--substitution_xKICJ{fill:var(--TextSecondary)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"statistic-timeline-incident-icon--card-yellow": `statistic-timeline-incident-icon--card-yellow_nJSfH`,
	"statistic-timeline-incident-icon--card-red": `statistic-timeline-incident-icon--card-red_vELCq`,
	"statistic-timeline-incident-icon--goal": `statistic-timeline-incident-icon--goal_U_JLn`,
	"statistic-timeline-incident-icon--disabled-goal": `statistic-timeline-incident-icon--disabled-goal_2kvnI`,
	"statistic-timeline-incident-icon--own-goal": `statistic-timeline-incident-icon--own-goal_nhqEi`,
	"statistic-timeline-incident-icon--substitution": `statistic-timeline-incident-icon--substitution_xKICJ`
};
export default ___CSS_LOADER_EXPORT___;
