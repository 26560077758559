// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.suspended-info_p3JMP{display:flex;flex-direction:column;align-items:center;padding:16px 0;background-color:var(--Layer1);border-radius:5px}.suspended-info__title__wWT8{font-size:18px;font-weight:500;line-height:28px;letter-spacing:normal;color:var(--TextDefault)}.suspended-info__description_mOGLD{font-size:14px;font-weight:400;line-height:24px;letter-spacing:.25px;color:var(--TextPrimary)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"suspended-info": `suspended-info_p3JMP`,
	"suspended-info__title": `suspended-info__title__wWT8`,
	"suspended-info__description": `suspended-info__description_mOGLD`
};
export default ___CSS_LOADER_EXPORT___;
