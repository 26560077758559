import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { toRef } from 'vue';
import StatisticTeamLogo from 'web/src/modules/sportline/submodules/event-statistic/components/StatisticTeamLogo.vue';
import { useLeaguePlayoffMatchesBlock } from './useLeaguePlayoffMatchesBlock';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LeaguePlayoffMatchesBlockDetalis',
    props: {
        block: {}
    },
    emits: [
        "require-close"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const block = toRef(props, 'block');
        const { matchScores, matchesHistory, participants } = useLeaguePlayoffMatchesBlock({
            block
        });
        function onBlockClick() {
            emit('require-close');
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['match-block-details']),
                onClick: onBlockClick
            }, [
                _createElementVNode("div", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(participants), (participant, participantIndex)=>(_openBlock(), _createElementBlock("div", {
                            key: participantIndex,
                            class: _normalizeClass({
                                [_ctx.$style['match-block-details-team']]: true,
                                [_ctx.$style['match-block-details-team--winner']]: participant.winner
                            })
                        }, [
                            participant.team ? (_openBlock(), _createElementBlock(_Fragment, {
                                key: 0
                            }, [
                                _createElementVNode("div", {
                                    class: _normalizeClass(_ctx.$style['match-block-details-team__identity'])
                                }, [
                                    _createVNode(StatisticTeamLogo, {
                                        class: _normalizeClass(_ctx.$style['match-block-details-team__logo']),
                                        team: participant.team
                                    }, null, 8, [
                                        "class",
                                        "team"
                                    ]),
                                    _createElementVNode("div", {
                                        class: _normalizeClass(_ctx.$style['match-block-details-team__name'])
                                    }, _toDisplayString(participant.team.name), 3)
                                ], 2),
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(matchScores), (scores, scoresKey)=>(_openBlock(), _createElementBlock("div", {
                                        key: scoresKey,
                                        class: _normalizeClass(_ctx.$style['match-block-details-team__result-score'])
                                    }, _toDisplayString(participant.team.id ? scores[participant.team.id] || '-' : '-'), 3))), 128))
                            ], 64)) : _createCommentVNode("", true)
                        ], 2))), 128))
                ]),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['match-block-details-team-history'])
                }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(matchesHistory), (item, itemIndex)=>(_openBlock(), _createElementBlock("div", {
                            key: itemIndex,
                            class: _normalizeClass(_ctx.$style['match-block-details-team-history__item'])
                        }, [
                            _createElementVNode("div", {
                                class: _normalizeClass([
                                    _ctx.$style['match-block-details-team-history__item-content'],
                                    _ctx.$style['match-block-details-team-history__date']
                                ])
                            }, _toDisplayString(item.date), 3),
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['match-block-details-team-history__item-content'])
                            }, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.names, (name, nameIndex)=>(_openBlock(), _createElementBlock("span", {
                                        key: nameIndex,
                                        class: _normalizeClass(_ctx.$style['match-block-details-team-history__name'])
                                    }, _toDisplayString(name), 3))), 128))
                            ], 2),
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['match-block-details-team-history__item-content'])
                            }, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.scores, (score, scoreIndex)=>(_openBlock(), _createElementBlock("span", {
                                        key: scoreIndex,
                                        class: _normalizeClass(_ctx.$style['match-block-details-team-history__score'])
                                    }, _toDisplayString(score || ' '), 3))), 128))
                            ], 2)
                        ], 2))), 128))
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'LeaguePlayoffMatchesBlockDetalis'
                ]
            ]);
        };
    }
});
