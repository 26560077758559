// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .events-list_v7F87{position:relative;padding:0}html.app__layout--desktop .events-list--extra-paddings_lrOR2{padding:0}html.app__layout--desktop .events-list-filter_zFFB5{padding:0 0 8px 0}html.app__layout--desktop .events-list-filter--extra-paddings_TMz3a{padding:0;margin:0 0 16px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"events-list": `events-list_v7F87`,
	"events-list--extra-paddings": `events-list--extra-paddings_lrOR2`,
	"events-list-filter": `events-list-filter_zFFB5`,
	"events-list-filter--extra-paddings": `events-list-filter--extra-paddings_TMz3a`
};
export default ___CSS_LOADER_EXPORT___;
