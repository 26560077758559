// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .virtual-sport_cb23a{min-height:100vh}html.app__layout--desktop .virtual-sport__title_euOlI{font-size:18px;font-weight:500;line-height:20px;letter-spacing:normal;position:relative;padding:16px;margin:0;color:var(--TextDefault)}html.app__layout--desktop .virtual-sport__widget_IvxJH{padding:0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"virtual-sport": `virtual-sport_cb23a`,
	"virtual-sport__title": `virtual-sport__title_euOlI`,
	"virtual-sport__widget": `virtual-sport__widget_IvxJH`
};
export default ___CSS_LOADER_EXPORT___;
