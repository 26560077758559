import { defineComponent as _defineComponent } from 'vue';
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, normalizeClass as _normalizeClass, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { TeamsConfrontationStatisticType } from 'web/src/modules/sportline/submodules/event-statistic/enums';
import VList from 'web/src/components/List/VList/VList.vue';
import VListItemAccordion from 'web/src/components/List/VListItemAccordion/VListItemAccordion.vue';
import TeamsConfrontationStatisticBlock from './TeamsConfrontationStatisticBlock.vue';
import TeamBallPossessionStatisticBlock from './TeamBallPossessionStatisticBlock.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'TeamsConfrontationStatistic',
    props: {
        teams: {},
        confrontationBlocks: {},
        sportFamily: {}
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(VList, {
                "independent-items": ""
            }, {
                default: _withCtx(()=>[
                        _createVNode(VListItemAccordion, {
                            id: "teams-confrontation-statistic",
                            title: _ctx.$t('WEB2_MATCH_STATISTICS'),
                            "is-title-centered": "",
                            open: ""
                        }, {
                            content: _withCtx(()=>[
                                    _createElementVNode("div", {
                                        class: _normalizeClass(_ctx.$style['teams-confrontation-statistic__content'])
                                    }, [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.confrontationBlocks, (block, index)=>(_openBlock(), _createElementBlock(_Fragment, {
                                                key: `${block.type}_${index}`
                                            }, [
                                                block.type === _unref(TeamsConfrontationStatisticType).BallPossession ? (_openBlock(), _createBlock(TeamBallPossessionStatisticBlock, {
                                                    key: 0,
                                                    class: _normalizeClass(_ctx.$style['teams-confrontation-statistic__block--ball-possession']),
                                                    "confrontation-block": block,
                                                    teams: _ctx.teams,
                                                    "sport-family": _ctx.sportFamily
                                                }, null, 8, [
                                                    "class",
                                                    "confrontation-block",
                                                    "teams",
                                                    "sport-family"
                                                ])) : (_openBlock(), _createBlock(TeamsConfrontationStatisticBlock, {
                                                    key: 1,
                                                    "confrontation-block": block
                                                }, null, 8, [
                                                    "confrontation-block"
                                                ]))
                                            ], 64))), 128))
                                    ], 2)
                                ]),
                            _: 1
                        }, 8, [
                            "title"
                        ])
                    ]),
                _: 1
            })), [
                [
                    _directive_auto_id,
                    'TeamsConfrontationStatistic'
                ]
            ]);
        };
    }
});
