// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.statistic-chart-labels_iR4Q_{display:flex;justify-content:space-between}.statistic-chart-labels__item_OIxOt{min-width:20%;text-align:center}.statistic-chart-labels__item_OIxOt:first-child{text-align:left}.statistic-chart-labels__item_OIxOt:last-child{text-align:right}.statistic-chart-label-indicator_gq7KB{display:inline-block}.statistic-chart-label-indicator_gq7KB:before{display:inline-block;width:8px;height:8px;border-radius:2px;content:" ";background:#0000}.statistic-chart-label-indicator--home_Fep3T:before{background:var(--StatsA)}.statistic-chart-label-indicator--draw_fpeNq:before{background:var(--StatsDraw)}.statistic-chart-label-indicator--away_BknKi:before{background:var(--StatsB)}.league-matches-statistic-content_YGRoq{display:grid;grid-template-columns:1fr 1fr;gap:8px}@media(max-width:699px){.league-matches-statistic-content_YGRoq{grid-template-columns:1fr}}.matches-total-wins_AkafR{display:grid;grid-template-columns:1fr;grid-column:1/-1;gap:12px;padding:12px 8px;background-color:var(--Layer2);border-radius:5px}.matches-total-wins__label-holder_cBKa9{display:grid;grid-template-columns:auto 1fr;row-gap:4px}.matches-total-wins__label-holder_cBKa9>:first-child{font-size:14px;font-weight:500;line-height:16px;letter-spacing:.25px;grid-row:1/3;margin-right:4px}.matches-total-wins__label_qFPc4{font-size:12px;font-weight:500;line-height:12px;letter-spacing:normal;color:var(--TextPrimary)}.matches-total-wins__label--primary_QjnGB{font-size:14px;font-weight:500;line-height:16px;letter-spacing:.25px;color:var(--TextDefault)}@media(max-width:699px){.matches-total-wins_AkafR{grid-column:1}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"statistic-chart-labels": `statistic-chart-labels_iR4Q_`,
	"statistic-chart-labels__item": `statistic-chart-labels__item_OIxOt`,
	"statistic-chart-label-indicator": `statistic-chart-label-indicator_gq7KB`,
	"statistic-chart-label-indicator--home": `statistic-chart-label-indicator--home_Fep3T`,
	"statistic-chart-label-indicator--draw": `statistic-chart-label-indicator--draw_fpeNq`,
	"statistic-chart-label-indicator--away": `statistic-chart-label-indicator--away_BknKi`,
	"league-matches-statistic-content": `league-matches-statistic-content_YGRoq`,
	"matches-total-wins": `matches-total-wins_AkafR`,
	"matches-total-wins__label-holder": `matches-total-wins__label-holder_cBKa9`,
	"matches-total-wins__label": `matches-total-wins__label_qFPc4`,
	"matches-total-wins__label--primary": `matches-total-wins__label--primary_QjnGB`
};
export default ___CSS_LOADER_EXPORT___;
