// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sport-event-league-statistic_P5LMF{position:relative}.sport-event-league-statistic__content-title_mNIrk{font-size:18px;font-weight:500;line-height:20px;letter-spacing:normal;padding:18px 0 18px 16px;color:var(--TextDefault)}.sport-event-league-statistic__blocks_tuHTi{display:flex;flex-direction:column;gap:8px}.sport-event-league-statistic__block_TDVjO{margin:0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sport-event-league-statistic": `sport-event-league-statistic_P5LMF`,
	"sport-event-league-statistic__content-title": `sport-event-league-statistic__content-title_mNIrk`,
	"sport-event-league-statistic__blocks": `sport-event-league-statistic__blocks_tuHTi`,
	"sport-event-league-statistic__block": `sport-event-league-statistic__block_TDVjO`
};
export default ___CSS_LOADER_EXPORT___;
