import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import VLogoLoader from 'web/src/components/Loader/VLogoLoader/VLogoLoader.vue';
import VIframe from 'web/src/components/Iframe/VIframe/VIframe.vue';
import { useSportradarWidgetRoutePage } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SportradarWidgetRoutePage',
    props: {
        type: {}
    },
    setup (__props) {
        const props = __props;
        const { frame, internalWidgetLoaded, isIframeVisible, iframeWidgetSrc, iframeName, additionalQueryStringParameters, initPostMessageBus } = useSportradarWidgetRoutePage(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['sportradar-statistics'])
            }, [
                _unref(internalWidgetLoaded) ? _createCommentVNode("", true) : (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['sportradar-statistics__loader'])
                }, [
                    _createVNode(VLogoLoader, {
                        class: _normalizeClass(_ctx.$style['sportradar-statistics__loader-image'])
                    }, null, 8, [
                        "class"
                    ])
                ], 2)),
                _unref(isIframeVisible) ? (_openBlock(), _createBlock(VIframe, {
                    key: 1,
                    ref_key: "frame",
                    ref: frame,
                    class: _normalizeClass(_ctx.$style['sportradar-statistics__widget']),
                    src: _unref(iframeWidgetSrc),
                    "full-width": "",
                    name: _unref(iframeName),
                    "additional-query-string-parameters": _unref(additionalQueryStringParameters),
                    onVnodeMounted: _unref(initPostMessageBus)
                }, null, 8, [
                    "class",
                    "src",
                    "name",
                    "additional-query-string-parameters",
                    "onVnodeMounted"
                ])) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'SportradarWidgetRoutePage'
                ]
            ]);
        };
    }
});
