// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .no-active-stream-widget_uSJT2{display:flex;flex-direction:column;gap:16px;align-items:center;justify-content:center;width:100%;height:100%;padding:16px;background-color:var(--Layer1)}html.app__layout--desktop .no-active-stream-icon-holder_CCyr_{display:flex;align-items:center;justify-content:center;color:var(--TextSecondary);fill:var(--TextSecondary)}html.app__layout--desktop .no-active-stream-description_bLuSC{display:flex;flex-direction:column;gap:8px;align-items:center;justify-content:center;text-align:center}html.app__layout--desktop .no-active-stream-description__title_vfStJ{font-size:14px;font-weight:500;line-height:16px;letter-spacing:.25px;color:var(--TextDefault)}html.app__layout--desktop .no-active-stream-description__subtitle_BxuTr{font-size:12px;font-weight:400;line-height:16px;letter-spacing:normal;color:var(--TextSecondary)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"no-active-stream-widget": `no-active-stream-widget_uSJT2`,
	"no-active-stream-icon-holder": `no-active-stream-icon-holder_CCyr_`,
	"no-active-stream-description": `no-active-stream-description_bLuSC`,
	"no-active-stream-description__title": `no-active-stream-description__title_vfStJ`,
	"no-active-stream-description__subtitle": `no-active-stream-description__subtitle_BxuTr`
};
export default ___CSS_LOADER_EXPORT___;
