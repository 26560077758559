// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.debug-indicator_OxIS5{z-index:5;position:absolute;top:0;right:0;display:flex;align-items:center;justify-content:center;width:20px;height:20px;color:var(--AlertDefault)}.debug-indicator-progress_4Ef8I{position:relative;width:16px;height:16px;overflow:hidden;background-color:#0000;border:2px solid var(--AlertDefault);border-radius:50%}.debug-indicator-progress_4Ef8I:before{position:absolute;width:100%;height:100%;content:"";background:var(--AlertDefault);transform:translateX(-100%);animation-name:delay-progress_zpsoY;animation-duration:inherit;animation-timing-function:linear;animation-iteration-count:infinite}@keyframes delay-progress_zpsoY{0%{transform:translateX(-100%)}to{transform:translateX(0)}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"debug-indicator": `debug-indicator_OxIS5`,
	"debug-indicator-progress": `debug-indicator-progress_4Ef8I`,
	"delay-progress": `delay-progress_zpsoY`
};
export default ___CSS_LOADER_EXPORT___;
