// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.headline-team-score_rTYjD{position:relative}.headline-team-score--size-32_zvwfv{font-size:32px;font-weight:700;line-height:32px}.headline-team-score--size-24_Z7Bmd{font-size:24px;font-weight:700;line-height:24px}.headline-team-score--gap-16-host_fBvXq{margin:0 8px 0 16px}.headline-team-score--gap-16-guest_w1LFB{margin:0 16px 0 8px}.headline-team-score--guest_eMSHO.active_YpZlB:before{position:absolute;top:0;right:-12px;content:"•"}.headline-team-score--host_fdSj1.active_YpZlB:before{position:absolute;top:0;left:-12px;content:"•"}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headline-team-score": `headline-team-score_rTYjD`,
	"headline-team-score--size-32": `headline-team-score--size-32_zvwfv`,
	"headline-team-score--size-24": `headline-team-score--size-24_Z7Bmd`,
	"headline-team-score--gap-16-host": `headline-team-score--gap-16-host_fBvXq`,
	"headline-team-score--gap-16-guest": `headline-team-score--gap-16-guest_w1LFB`,
	"headline-team-score--guest": `headline-team-score--guest_eMSHO`,
	"active": `active_YpZlB`,
	"headline-team-score--host": `headline-team-score--host_fdSj1`
};
export default ___CSS_LOADER_EXPORT___;
