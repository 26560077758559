// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.post-match-statistic-headline_itnCP{display:flex;flex-direction:column;justify-content:center;height:auto;overflow:hidden;color:var(--White);border-radius:5px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"post-match-statistic-headline": `post-match-statistic-headline_itnCP`
};
export default ___CSS_LOADER_EXPORT___;
