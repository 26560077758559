// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.headline-layout--default_vkw3B{color:var(--BasedOnSportTextDefault)}.headline-layout--default_vkw3B .headline-layout__info_BZKSu{background-color:var(--Layer1)}.headline-layout--colored_qpa8G,.headline-layout--colored_qpa8G h1,.headline-layout--colored_qpa8G h2,.headline-layout--colored_qpa8G h3,.headline-layout--colored_qpa8G h4{color:var(--BasedOnSportTextColored)}html.app__layout--desktop .headline-layout_C3AxQ{display:flex;gap:4px;justify-content:space-between;overflow:hidden;border-radius:5px;transition:height .3s cubic-bezier(.4,0,.2,1)}html.app__layout--desktop .headline-layout__info_BZKSu{display:flex;flex:1;flex-direction:column;align-items:stretch;justify-content:center;border-radius:5px 0 0}html.app__layout--desktop .headline-layout__info_BZKSu:last-child{border-radius:5px}html.app__layout--desktop .headline-layout__info--full-height_gwnfY{height:100%}html.app__layout--desktop .headline-layout__info--full-width_NJJjZ{width:100%;margin-right:0}.headline-layout--live-default_wlLzn{min-height:234px}.headline-layout--live-low_mb41j,.headline-layout--prematch-default_iuN5v{min-height:172px}.headline-layout--prematch--large_LrXFa{min-height:234px}.headline-layout--outright-default_D_0Ut{min-height:181px}.headline-layout--outright--large_RyAmD{min-height:234px}.inplay-component_QCCOA{position:relative;width:325px;height:200px;min-height:100%;overflow:hidden}.inplay-component--extended_Ko9P6{height:290px}.inplay-component__holder_LNhlm{display:flex;align-items:center;justify-content:center;min-height:234px;background-color:var(--Layer1);border-radius:5px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headline-layout--default": `headline-layout--default_vkw3B`,
	"headline-layout__info": `headline-layout__info_BZKSu`,
	"headline-layout--colored": `headline-layout--colored_qpa8G`,
	"headline-layout": `headline-layout_C3AxQ`,
	"headline-layout__info--full-height": `headline-layout__info--full-height_gwnfY`,
	"headline-layout__info--full-width": `headline-layout__info--full-width_NJJjZ`,
	"headline-layout--live-default": `headline-layout--live-default_wlLzn`,
	"headline-layout--live-low": `headline-layout--live-low_mb41j`,
	"headline-layout--prematch-default": `headline-layout--prematch-default_iuN5v`,
	"headline-layout--prematch--large": `headline-layout--prematch--large_LrXFa`,
	"headline-layout--outright-default": `headline-layout--outright-default_D_0Ut`,
	"headline-layout--outright--large": `headline-layout--outright--large_RyAmD`,
	"inplay-component": `inplay-component_QCCOA`,
	"inplay-component--extended": `inplay-component--extended_Ko9P6`,
	"inplay-component__holder": `inplay-component__holder_LNhlm`
};
export default ___CSS_LOADER_EXPORT___;
