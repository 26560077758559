// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.primary-market-name_LuhE5{font-size:13px;font-weight:400;line-height:16px;letter-spacing:normal;display:flex;align-items:center;justify-content:center;width:100%;padding:2px 12px;color:var(--TextDefault);background-color:var(--Layer2);border-radius:5px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primary-market-name": `primary-market-name_LuhE5`
};
export default ___CSS_LOADER_EXPORT___;
