// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .live-widget-slide_RsC99{display:flex;align-items:baseline;justify-content:center;width:100%;height:100%;background-color:#0b1f39}html.app__layout--desktop .live-widget-slide__component_G9YtR{flex:none;width:305px;height:100%}html.app__layout--desktop .live-widget-slide-loader_dwqUq{display:flex;align-items:center;justify-content:center;min-height:200px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"live-widget-slide": `live-widget-slide_RsC99`,
	"live-widget-slide__component": `live-widget-slide__component_G9YtR`,
	"live-widget-slide-loader": `live-widget-slide-loader_dwqUq`
};
export default ___CSS_LOADER_EXPORT___;
