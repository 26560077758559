// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sportline-toolbar_gv6bv{display:flex;align-items:stretch;justify-content:space-between;width:100%;min-height:44px}html.app__layout--desktop .sportline-toolbar_gv6bv{margin-bottom:8px;padding-right:0}.sportline-toolbar__title-block_vp8K5{padding:14px 8px 14px 8px}html.app__layout--desktop .sportline-toolbar__title-block_vp8K5{display:flex;align-items:center;justify-content:center;padding:12px 8px;text-align:center}.sportline-toolbar__title_O7QZp{color:var(--TextDefault);font-size:13px;font-weight:500;line-height:16px;letter-spacing:normal}html.app__layout--desktop .sportline-toolbar__title_O7QZp{font-size:18px;font-weight:500;line-height:20px;letter-spacing:normal}.sportline-toolbar__subtitle_apI6Y{display:inline-block;margin-right:4px;color:var(--TextPrimary);font-size:13px;font-weight:400;line-height:16px;letter-spacing:normal}html.app__layout--desktop .sportline-toolbar__subtitle_apI6Y{margin-right:0}.sportline-toolbar__right-block_EjLmC{display:flex;align-items:flex-start}.sport-event-league-toolbar__back_YDpHJ{width:auto;min-width:44px;margin:auto 0;color:var(--TextPrimary);background-color:inherit;border:0}.sport-event-league-toolbar__back_YDpHJ:hover{color:var(--TextDefault)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sportline-toolbar": `sportline-toolbar_gv6bv`,
	"sportline-toolbar__title-block": `sportline-toolbar__title-block_vp8K5`,
	"sportline-toolbar__title": `sportline-toolbar__title_O7QZp`,
	"sportline-toolbar__subtitle": `sportline-toolbar__subtitle_apI6Y`,
	"sportline-toolbar__right-block": `sportline-toolbar__right-block_EjLmC`,
	"sport-event-league-toolbar__back": `sport-event-league-toolbar__back_YDpHJ`
};
export default ___CSS_LOADER_EXPORT___;
