// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.league-playoff-round_ymwOX{position:relative;display:flex;flex:0 0 220px;flex-direction:column;justify-content:center;width:220px}.league-playoff-round__title_VZ3WC{font-size:14px;font-weight:500;line-height:16px;letter-spacing:.25px;display:block;width:calc(100% - 40px);padding:8px;margin-bottom:8px;overflow:hidden;color:var(--TextDefault);text-align:center;text-overflow:ellipsis;white-space:nowrap;background-color:var(--Layer2);border-radius:5px}.league-playoff-round__block-pair_SZHyQ{display:flex;flex:1 1 0}.league-playoff-round__block-pair-content_ZejLu{display:flex;flex:1 0 calc(100% - 40px);flex-direction:column;justify-content:space-around;width:calc(100% - 40px)}.league-playoff-round__block-holder_N56RB{padding:8px 0}.league-playoff-round__arrow_Q8tF9{display:flex;flex:0 0 40px;align-items:center;width:40px}.league-playoff-round__arrow-line_H_Ck4{position:relative;z-index:2;width:50%;height:50%;border-top:1px solid var(--Highlight);border-right:1px solid var(--Highlight);border-bottom:1px solid var(--Highlight);border-left:none;border-image:initial}.league-playoff-round__arrow-line_H_Ck4:before{position:absolute;top:50%;left:100%;width:110%;height:1px;content:"";background-color:var(--Highlight)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"league-playoff-round": `league-playoff-round_ymwOX`,
	"league-playoff-round__title": `league-playoff-round__title_VZ3WC`,
	"league-playoff-round__block-pair": `league-playoff-round__block-pair_SZHyQ`,
	"league-playoff-round__block-pair-content": `league-playoff-round__block-pair-content_ZejLu`,
	"league-playoff-round__block-holder": `league-playoff-round__block-holder_N56RB`,
	"league-playoff-round__arrow": `league-playoff-round__arrow_Q8tF9`,
	"league-playoff-round__arrow-line": `league-playoff-round__arrow-line_H_Ck4`
};
export default ___CSS_LOADER_EXPORT___;
