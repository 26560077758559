// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sport-event-toolbar_jl4xY{display:flex;align-items:stretch;justify-content:space-between;width:100%;min-height:44px}.sport-event-toolbar__region_D1PHE{font-size:13px;font-weight:400;line-height:16px;letter-spacing:normal;display:inline-block;margin-right:4px;color:var(--TextPrimary)}.sport-event-toolbar__label_ssdk3{display:flex;flex-wrap:wrap;align-items:center;padding:14px 8px}.sport-event-toolbar__wrapper_lmYkZ{display:flex}.sport-event-toolbar__name_ZXDp5{font-size:13px;font-weight:500;line-height:16px;letter-spacing:normal;color:var(--TextDefault)}.sport-event-toolbar__button-container_kmQhz{display:flex;align-items:flex-start}.sport-event-toolbar__expand-button_kWX97{margin-left:8px}.sport-event-toolbar__dropdown-container_g4EuC{height:100%}html.app__layout--desktop .sport-event-toolbar_jl4xY{padding-right:0;margin-bottom:8px}html.app__layout--desktop .sport-event-toolbar_jl4xY .sport-event-toolbar__name_ZXDp5{font-size:18px;font-weight:500;line-height:20px;letter-spacing:normal;color:var(--TextDefault)}html.app__layout--desktop .sport-event-toolbar_jl4xY .sport-event-toolbar__region_D1PHE{margin-right:0}html.app__layout--desktop .sport-event-toolbar_jl4xY .sport-event-toolbar__label_ssdk3{display:flex;align-items:center;justify-content:center;padding:12px 8px;text-align:center}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sport-event-toolbar": `sport-event-toolbar_jl4xY`,
	"sport-event-toolbar__region": `sport-event-toolbar__region_D1PHE`,
	"sport-event-toolbar__label": `sport-event-toolbar__label_ssdk3`,
	"sport-event-toolbar__wrapper": `sport-event-toolbar__wrapper_lmYkZ`,
	"sport-event-toolbar__name": `sport-event-toolbar__name_ZXDp5`,
	"sport-event-toolbar__button-container": `sport-event-toolbar__button-container_kmQhz`,
	"sport-event-toolbar__expand-button": `sport-event-toolbar__expand-button_kWX97`,
	"sport-event-toolbar__dropdown-container": `sport-event-toolbar__dropdown-container_g4EuC`
};
export default ___CSS_LOADER_EXPORT___;
