// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.last-matches-statistic_Wyrnx{padding:4px;background-color:var(--Layer2);border-radius:5px}.last-matches-statistic-result-indicators_eGi3v{display:flex}.last-matches-statistic-result-indicators__container_WdHLJ{display:flex;justify-content:space-between;padding:12px 4px 4px;border-top:1px solid var(--Layer1)}.last-matches-statistic-result-indicator_Xy3lL{padding:0 4px}.last-matches-statistic-result-indicator_Xy3lL:first-child{padding-left:0}.last-matches-statistic-result-indicator_Xy3lL:last-child{padding-right:0}.last-matches-statistic-result-percentages_X0z2H{font-size:12px;font-weight:500;line-height:12px;letter-spacing:normal;color:var(--TextDefault)}.last-matches-statistic-result-percentages__container_Elfar{display:flex;justify-content:space-between;padding:8px 4px}.last-matches-statistic-result-percentages--central_DFVW4{color:var(--TextPrimary)}.last-matches-statistic-result-graph_lZ4jt{position:relative;width:100%;height:4px;background-color:var(--Highlight);border-radius:2px}.last-matches-statistic-result-graph__container_hQs9k{display:flex;justify-content:space-between;padding:0 4px 12px}.last-matches-statistic-result-graph--left_c4moW{margin-right:2px}.last-matches-statistic-result-graph--right_Hun1G{margin-left:2px}.last-matches-statistic-result-graph__filler_Ehvz_{height:100%;border-radius:2px}.last-matches-statistic-result-graph__filler--left_J24EV{position:absolute;right:0;background:var(--StatsA)}.last-matches-statistic-result-graph__filler--right_QraSi{position:absolute;left:0;background:var(--StatsB)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"last-matches-statistic": `last-matches-statistic_Wyrnx`,
	"last-matches-statistic-result-indicators": `last-matches-statistic-result-indicators_eGi3v`,
	"last-matches-statistic-result-indicators__container": `last-matches-statistic-result-indicators__container_WdHLJ`,
	"last-matches-statistic-result-indicator": `last-matches-statistic-result-indicator_Xy3lL`,
	"last-matches-statistic-result-percentages": `last-matches-statistic-result-percentages_X0z2H`,
	"last-matches-statistic-result-percentages__container": `last-matches-statistic-result-percentages__container_Elfar`,
	"last-matches-statistic-result-percentages--central": `last-matches-statistic-result-percentages--central_DFVW4`,
	"last-matches-statistic-result-graph": `last-matches-statistic-result-graph_lZ4jt`,
	"last-matches-statistic-result-graph__container": `last-matches-statistic-result-graph__container_hQs9k`,
	"last-matches-statistic-result-graph--left": `last-matches-statistic-result-graph--left_c4moW`,
	"last-matches-statistic-result-graph--right": `last-matches-statistic-result-graph--right_Hun1G`,
	"last-matches-statistic-result-graph__filler": `last-matches-statistic-result-graph__filler_Ehvz_`,
	"last-matches-statistic-result-graph__filler--left": `last-matches-statistic-result-graph__filler--left_J24EV`,
	"last-matches-statistic-result-graph__filler--right": `last-matches-statistic-result-graph__filler--right_QraSi`
};
export default ___CSS_LOADER_EXPORT___;
