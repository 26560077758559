// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filled-sportline-logo-background_Ggu0t{display:flex;align-items:center;justify-content:center;text-align:center;background:var(--White);border-radius:15px}.filled-sportline-logo-background--full-size_STrJe{flex:0 0 100%;width:100%;height:100%}.filled-sportline-logo-background--84_sr_qe{flex:0 0 84px;width:84px;height:84px}.filled-sportline-logo-background--52_OwM5B{flex:0 0 52px;width:52px;height:52px}.filled-sportline-logo-background--40_lf8n4{flex:0 0 40px;width:40px;height:40px}.filled-sportline-logo-background--32_auAPN{flex:0 0 32px;width:32px;height:32px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filled-sportline-logo-background": `filled-sportline-logo-background_Ggu0t`,
	"filled-sportline-logo-background--full-size": `filled-sportline-logo-background--full-size_STrJe`,
	"filled-sportline-logo-background--84": `filled-sportline-logo-background--84_sr_qe`,
	"filled-sportline-logo-background--52": `filled-sportline-logo-background--52_OwM5B`,
	"filled-sportline-logo-background--40": `filled-sportline-logo-background--40_lf8n4`,
	"filled-sportline-logo-background--32": `filled-sportline-logo-background--32_auAPN`
};
export default ___CSS_LOADER_EXPORT___;
