import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, unref as _unref, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { computed, toRef } from 'vue';
import { IconSize } from '@leon-hub/icons';
import { SportIcon } from '@components/sport-icon';
import StatisticTeamLogo from 'web/src/modules/sportline/submodules/event-statistic/components/StatisticTeamLogo.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'TeamBallPossessionStatisticBlock',
    props: {
        confrontationBlock: {},
        teams: {},
        sportFamily: {}
    },
    setup (__props) {
        const props = __props;
        const teams = toRef(props, 'teams', null);
        const sportFamily = toRef(props, 'sportFamily', null);
        const host = computed(()=>teams.value?.[0] ?? null);
        const guest = computed(()=>teams.value?.[1] ?? null);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['ball-possession-statistic-block'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['ball-possession-statistic-block__heading'])
                }, [
                    _createElementVNode("span", {
                        class: _normalizeClass(_ctx.$style['ball-possession-chart-heading'])
                    }, _toDisplayString(_ctx.confrontationBlock.name), 3)
                ], 2),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['ball-possession-statistic-block__charts'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['ball-possession-chart-bar'])
                    }, [
                        _createElementVNode("div", {
                            class: _normalizeClass([
                                _ctx.$style['ball-possession-chart-bar__filling-borders'],
                                _ctx.$style['ball-possession-chart-bar__filling-borders--left']
                            ])
                        }, [
                            _createElementVNode("div", {
                                class: _normalizeClass([
                                    _ctx.$style['ball-possession-chart-bar__filling'],
                                    _ctx.$style['ball-possession-chart-bar__filling--left']
                                ]),
                                style: _normalizeStyle({
                                    width: `${_ctx.confrontationBlock.host.percent}%`
                                })
                            }, null, 6)
                        ], 2),
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['ball-possession-team__holder'])
                        }, [
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['ball-possession-team'])
                            }, [
                                _createElementVNode("div", null, [
                                    host.value ? (_openBlock(), _createBlock(StatisticTeamLogo, {
                                        key: 0,
                                        class: _normalizeClass(_ctx.$style['ball-possession-team__logo']),
                                        team: host.value
                                    }, null, 8, [
                                        "class",
                                        "team"
                                    ])) : _createCommentVNode("", true)
                                ]),
                                _createElementVNode("div", {
                                    class: _normalizeClass(_ctx.$style['ball-possession-team__name'])
                                }, [
                                    host.value ? (_openBlock(), _createElementBlock(_Fragment, {
                                        key: 0
                                    }, [
                                        _createTextVNode(_toDisplayString(host.value.name), 1)
                                    ], 64)) : _createCommentVNode("", true)
                                ], 2),
                                _createElementVNode("div", {
                                    class: _normalizeClass(_ctx.$style['ball-possession-team__score'])
                                }, _toDisplayString(_ctx.confrontationBlock.host.value), 3)
                            ], 2)
                        ], 2)
                    ], 2),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['ball-possession-chart-bar'])
                    }, [
                        _createElementVNode("div", {
                            class: _normalizeClass([
                                _ctx.$style['ball-possession-chart-bar__filling-borders'],
                                _ctx.$style['ball-possession-chart-bar__filling-borders--right']
                            ])
                        }, [
                            _createElementVNode("div", {
                                class: _normalizeClass([
                                    _ctx.$style['ball-possession-chart-bar__filling'],
                                    _ctx.$style['ball-possession-chart-bar__filling--right']
                                ]),
                                style: _normalizeStyle({
                                    width: `${_ctx.confrontationBlock.guest.percent}%`
                                })
                            }, null, 6)
                        ], 2),
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['ball-possession-team__holder'])
                        }, [
                            _createElementVNode("div", {
                                class: _normalizeClass([
                                    _ctx.$style['ball-possession-team'],
                                    _ctx.$style['ball-possession-team--right']
                                ])
                            }, [
                                _createElementVNode("div", null, [
                                    guest.value ? (_openBlock(), _createBlock(StatisticTeamLogo, {
                                        key: 0,
                                        class: _normalizeClass(_ctx.$style['ball-possession-team__logo']),
                                        team: guest.value
                                    }, null, 8, [
                                        "class",
                                        "team"
                                    ])) : _createCommentVNode("", true)
                                ]),
                                _createElementVNode("div", {
                                    class: _normalizeClass([
                                        _ctx.$style['ball-possession-team__name'],
                                        _ctx.$style['ball-possession-team__name--right']
                                    ])
                                }, [
                                    guest.value ? (_openBlock(), _createElementBlock(_Fragment, {
                                        key: 0
                                    }, [
                                        _createTextVNode(_toDisplayString(guest.value.name), 1)
                                    ], 64)) : _createCommentVNode("", true)
                                ], 2),
                                _createElementVNode("div", {
                                    class: _normalizeClass([
                                        _ctx.$style['ball-possession-team__score'],
                                        _ctx.$style['ball-possession-team__score--right']
                                    ])
                                }, _toDisplayString(_ctx.confrontationBlock.guest.value), 3)
                            ], 2)
                        ], 2)
                    ], 2),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['ball-possession-sport-icon__holder'])
                    }, [
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['ball-possession-sport-icon'])
                        }, [
                            sportFamily.value ? (_openBlock(), _createBlock(_unref(SportIcon), {
                                key: 0,
                                size: _unref(IconSize).SIZE_16,
                                "sport-name": sportFamily.value
                            }, null, 8, [
                                "size",
                                "sport-name"
                            ])) : _createCommentVNode("", true)
                        ], 2)
                    ], 2)
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'TeamBallPossessionStatisticBlock'
                ]
            ]);
        };
    }
});
