// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .events-list_Ai7R_{position:relative;padding:0}html.app__layout--desktop .events-list--extra-paddings_xszEh{padding:0}html.app__layout--desktop .events-list-filter_JzxWg{margin:0 0 8px}html.app__layout--desktop .events-list-filter--extra-paddings_lDKkH{padding:0;margin:0 0 16px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"events-list": `events-list_Ai7R_`,
	"events-list--extra-paddings": `events-list--extra-paddings_xszEh`,
	"events-list-filter": `events-list-filter_JzxWg`,
	"events-list-filter--extra-paddings": `events-list-filter--extra-paddings_lDKkH`
};
export default ___CSS_LOADER_EXPORT___;
