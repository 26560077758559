// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.empty-content_jQAUD{display:flex;flex-direction:column;gap:8px;padding:32px;text-align:center}.empty-content__holder_dYiEu{display:flex;flex-direction:row;justify-content:center}.empty-content__title__P8wQ{font-size:18px;font-weight:500;line-height:28px;letter-spacing:normal;color:var(--TextDefault)}.empty-content__description_efDp7{font-size:14px;font-weight:400;line-height:24px;letter-spacing:.25px;color:var(--TextPrimary)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"empty-content": `empty-content_jQAUD`,
	"empty-content__holder": `empty-content__holder_dYiEu`,
	"empty-content__title": `empty-content__title__P8wQ`,
	"empty-content__description": `empty-content__description_efDp7`
};
export default ___CSS_LOADER_EXPORT___;
