// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.league-playoff-matches-block_JrT76{background-color:var(--Highlight);border:1px solid var(--Highlight);border-radius:5px}.league-playoff-matches-block__matches_sVhTM{display:grid;grid-template-columns:1fr;grid-gap:1px}.league-playoff-matches-block__match_g4a0T{font-size:14px;font-weight:500;line-height:16px;letter-spacing:.25px;color:var(--TextSecondary);background-color:var(--Layer2)}.league-playoff-matches-block__match--winner_u2CaZ{color:var(--TextDefault)}.match-block-team_myMkS{position:relative;padding:8px}.match-block-team_myMkS,.match-block-team__identity_m8egP{display:flex;align-items:center;overflow:hidden}.match-block-team__identity_m8egP{flex-grow:1}.match-block-team__logo_UX53U{display:flex;flex-shrink:0;align-items:center;justify-content:center;width:20px;height:20px;margin-right:8px;overflow:hidden}.match-block-team__name_UTrAV,.match-block-team__result-score_JyNKL{overflow:hidden;text-overflow:ellipsis;white-space:nowrap}.match-block-team__result-score_JyNKL{flex:0 0 20px;width:20px;text-align:right}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"league-playoff-matches-block": `league-playoff-matches-block_JrT76`,
	"league-playoff-matches-block__matches": `league-playoff-matches-block__matches_sVhTM`,
	"league-playoff-matches-block__match": `league-playoff-matches-block__match_g4a0T`,
	"league-playoff-matches-block__match--winner": `league-playoff-matches-block__match--winner_u2CaZ`,
	"match-block-team": `match-block-team_myMkS`,
	"match-block-team__identity": `match-block-team__identity_m8egP`,
	"match-block-team__logo": `match-block-team__logo_UX53U`,
	"match-block-team__name": `match-block-team__name_UTrAV`,
	"match-block-team__result-score": `match-block-team__result-score_JyNKL`
};
export default ___CSS_LOADER_EXPORT___;
