import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, isRef as _isRef, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { toRef } from 'vue';
import { ProgressHeight, ProgressKind } from 'web/src/components/LinearProgress/VLinearProgress/enums';
import VList from 'web/src/components/List/VList/VList.vue';
import VListItemAccordion from 'web/src/components/List/VListItemAccordion/VListItemAccordion.vue';
import VLinearProgress from 'web/src/components/LinearProgress/VLinearProgress/VLinearProgress.vue';
import ExpandButton from 'web/src/modules/sportline/submodules/core-statistic/components/ExpandButton.vue';
import { useLeagueMatchesResults } from './useLeagueMatchesResults';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LeagueMatchesResults',
    props: {
        leagueMatchesResults: {}
    },
    setup (__props) {
        const props = __props;
        const { resultsList, goalsList, isExpanded, canExpand } = useLeagueMatchesResults({
            leagueMatchesResults: toRef(props, 'leagueMatchesResults')
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(VList, {
                "independent-items": ""
            }, {
                default: _withCtx(()=>[
                        _createVNode(VListItemAccordion, {
                            id: "league-matches-results",
                            title: _ctx.$t('WEB2_STATISTICS_MATCHES_RESULTS'),
                            "is-title-centered": "",
                            open: ""
                        }, {
                            content: _withCtx(()=>[
                                    _createElementVNode("div", {
                                        class: _normalizeClass(_ctx.$style['league-matches-results__content'])
                                    }, [
                                        _createElementVNode("div", {
                                            class: _normalizeClass(_ctx.$style['matches-results-list'])
                                        }, [
                                            _createElementVNode("div", {
                                                class: _normalizeClass(_ctx.$style['matches-results-list__title'])
                                            }, _toDisplayString(_ctx.$t('WEB2_STATISTICS_ACCURATE_SCORE')), 3),
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(resultsList), (result)=>(_openBlock(), _createElementBlock("div", {
                                                    key: result.key,
                                                    class: _normalizeClass([
                                                        _ctx.$style['matches-results-list__item'],
                                                        _ctx.$style['match-result-item']
                                                    ])
                                                }, [
                                                    _createElementVNode("div", {
                                                        class: _normalizeClass(_ctx.$style['match-result-item__values'])
                                                    }, [
                                                        _createElementVNode("div", {
                                                            class: _normalizeClass(_ctx.$style['match-result-item__value'])
                                                        }, [
                                                            _createElementVNode("div", {
                                                                class: _normalizeClass([
                                                                    _ctx.$style['match-result-label'],
                                                                    _ctx.$style['match-result-label--light-background']
                                                                ])
                                                            }, _toDisplayString(result.label), 3)
                                                        ], 2),
                                                        _createElementVNode("div", {
                                                            class: _normalizeClass(_ctx.$style['match-result-item__value'])
                                                        }, [
                                                            _createElementVNode("div", {
                                                                class: _normalizeClass(_ctx.$style['match-result-label'])
                                                            }, _toDisplayString(result.valueLabel), 3)
                                                        ], 2),
                                                        _createElementVNode("div", {
                                                            class: _normalizeClass(_ctx.$style['match-result-item__value'])
                                                        }, [
                                                            _createElementVNode("div", {
                                                                class: _normalizeClass(_ctx.$style['match-result-label'])
                                                            }, _toDisplayString(result.percentageFixed) + "% ", 3)
                                                        ], 2)
                                                    ], 2),
                                                    _createElementVNode("div", {
                                                        class: _normalizeClass(_ctx.$style['match-result-item__bar'])
                                                    }, [
                                                        _createVNode(VLinearProgress, {
                                                            value: result.percentage,
                                                            height: _unref(ProgressHeight).SIZE_4,
                                                            kind: _unref(ProgressKind).HIGHLIGHT
                                                        }, null, 8, [
                                                            "value",
                                                            "height",
                                                            "kind"
                                                        ])
                                                    ], 2)
                                                ], 2))), 128))
                                        ], 2),
                                        _createElementVNode("div", {
                                            class: _normalizeClass(_ctx.$style['matches-results-list'])
                                        }, [
                                            _createElementVNode("div", {
                                                class: _normalizeClass(_ctx.$style['matches-results-list__title'])
                                            }, _toDisplayString(_ctx.$t('WEB2_STATISTICS_ACCURATE_GOALS')), 3),
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(goalsList), (goals)=>(_openBlock(), _createElementBlock("div", {
                                                    key: goals.key,
                                                    class: _normalizeClass([
                                                        _ctx.$style['matches-results-list__item'],
                                                        _ctx.$style['match-result-item']
                                                    ])
                                                }, [
                                                    _createElementVNode("div", {
                                                        class: _normalizeClass(_ctx.$style['match-result-item__values'])
                                                    }, [
                                                        _createElementVNode("div", {
                                                            class: _normalizeClass(_ctx.$style['match-result-item__value'])
                                                        }, [
                                                            _createElementVNode("div", {
                                                                class: _normalizeClass([
                                                                    _ctx.$style['match-result-label'],
                                                                    _ctx.$style['match-result-label--dark-background']
                                                                ])
                                                            }, _toDisplayString(goals.label), 3)
                                                        ], 2),
                                                        _createElementVNode("div", {
                                                            class: _normalizeClass(_ctx.$style['match-result-item__value'])
                                                        }, [
                                                            _createElementVNode("div", {
                                                                class: _normalizeClass(_ctx.$style['match-result-label'])
                                                            }, _toDisplayString(goals.valueLabel), 3)
                                                        ], 2),
                                                        _createElementVNode("div", {
                                                            class: _normalizeClass(_ctx.$style['match-result-item__value'])
                                                        }, [
                                                            _createElementVNode("div", {
                                                                class: _normalizeClass(_ctx.$style['match-result-label'])
                                                            }, _toDisplayString(goals.percentageFixed) + "% ", 3)
                                                        ], 2)
                                                    ], 2),
                                                    _createElementVNode("div", {
                                                        class: _normalizeClass(_ctx.$style['match-result-item__bar'])
                                                    }, [
                                                        _createVNode(VLinearProgress, {
                                                            value: goals.percentage,
                                                            height: _unref(ProgressHeight).SIZE_4,
                                                            kind: _unref(ProgressKind).HIGHLIGHT
                                                        }, null, 8, [
                                                            "value",
                                                            "height",
                                                            "kind"
                                                        ])
                                                    ], 2)
                                                ], 2))), 128))
                                        ], 2),
                                        _unref(canExpand) ? (_openBlock(), _createBlock(ExpandButton, {
                                            key: 0,
                                            "is-expanded": _unref(isExpanded),
                                            "onUpdate:isExpanded": _cache[0] || (_cache[0] = ($event)=>_isRef(isExpanded) ? isExpanded.value = $event : null),
                                            "opened-title": _ctx.$t('WEB2_MATCH_STATISTIC_HIDE'),
                                            "closed-title": _ctx.$t('WEB2_MATCH_STATISTIC_SHOW'),
                                            class: _normalizeClass(_ctx.$style['match-result-expand'])
                                        }, null, 8, [
                                            "is-expanded",
                                            "opened-title",
                                            "closed-title",
                                            "class"
                                        ])) : _createCommentVNode("", true)
                                    ], 2)
                                ]),
                            _: 1
                        }, 8, [
                            "title"
                        ])
                    ]),
                _: 1
            })), [
                [
                    _directive_auto_id,
                    'LeagueMatchesResults'
                ]
            ]);
        };
    }
});
