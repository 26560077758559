// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.post-match-statistic-simple-result-score_MQxsF{display:flex;place-content:center space-between;padding:16px 8px;background-color:var(--Layer2);border-radius:5px}.post-match-statistic-simple-result-score__teams_rEYk6{display:flex;flex-direction:column;justify-content:space-around}.post-match-statistic-simple-result-score__team_Dt9uS{display:flex;justify-content:flex-start}.post-match-statistic-simple-result-score__team_Dt9uS:first-child{margin-bottom:8px}.post-match-statistic-simple-result-score__team-name_F_IDp{font-size:14px;font-weight:500;line-height:16px;letter-spacing:.25px;padding-left:8px;color:var(--TextDefault)}.post-match-statistic-simple-result-score__team-logo_rIvfb{width:16px;height:16px}.post-match-statistic-simple-result-score__right_YYHDb{display:flex;justify-content:space-around}.post-match-statistic-simple-result-score__scores_KDi_w{font-size:13px;font-weight:700;line-height:16px;letter-spacing:-.7px;display:flex;flex-direction:column;justify-content:space-around;margin-right:8px;color:var(--TextSecondary);text-align:right}.post-match-statistic-simple-result-score__scores--final__VDfg{color:var(--TextDefault)}.post-match-statistic-simple-result-score__scores_KDi_w:last-child{margin:0}.post-match-statistic-simple-result-score__score_yrGjK:first-child{margin-bottom:8px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"post-match-statistic-simple-result-score": `post-match-statistic-simple-result-score_MQxsF`,
	"post-match-statistic-simple-result-score__teams": `post-match-statistic-simple-result-score__teams_rEYk6`,
	"post-match-statistic-simple-result-score__team": `post-match-statistic-simple-result-score__team_Dt9uS`,
	"post-match-statistic-simple-result-score__team-name": `post-match-statistic-simple-result-score__team-name_F_IDp`,
	"post-match-statistic-simple-result-score__team-logo": `post-match-statistic-simple-result-score__team-logo_rIvfb`,
	"post-match-statistic-simple-result-score__right": `post-match-statistic-simple-result-score__right_YYHDb`,
	"post-match-statistic-simple-result-score__scores": `post-match-statistic-simple-result-score__scores_KDi_w`,
	"post-match-statistic-simple-result-score__scores--final": `post-match-statistic-simple-result-score__scores--final__VDfg`,
	"post-match-statistic-simple-result-score__score": `post-match-statistic-simple-result-score__score_yrGjK`
};
export default ___CSS_LOADER_EXPORT___;
