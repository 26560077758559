// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pin-widget-switcher_MqhdM{display:flex;align-items:center;justify-content:center;width:32px;height:32px;padding:0;color:var(--White);cursor:pointer;background:#00000080;border:none;border-radius:5px}@media(hover:hover)and (pointer:fine){.pin-widget-switcher_MqhdM:hover{background:var(--Black)}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pin-widget-switcher": `pin-widget-switcher_MqhdM`
};
export default ___CSS_LOADER_EXPORT___;
