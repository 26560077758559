import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
import { onComponentActivated } from '@leon-hub/vue-utils';
import StreamWidget from 'web/src/modules/sportline/submodules/widgets/views/StreamWidget.vue';
import NoActiveStreamWidget from 'web/src/modules/sportline/submodules/widgets/views/NoActiveStreamWidget.vue';
import { useSportlineEventStream } from './useSportlineEventStream';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SportlineEventStream',
    props: {
        isActive: {
            type: Boolean
        },
        isFloatingModeAllowed: {
            type: Boolean
        }
    },
    emits: [
        "size-changed"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emit = __emit;
        const { isFloatingModeEnabled, isStreamAvailable, doShowStreamPreviewInfo, streamUrl, sportlineEventId, reloadStream, onStreamStarted, releaseStream } = useSportlineEventStream();
        onComponentActivated(()=>{
            emit('size-changed', {
                height: '100% '
            });
        });
        return (_ctx, _cache)=>_unref(isStreamAvailable) ? (_openBlock(), _createBlock(StreamWidget, {
                key: 0,
                "is-active": _ctx.isActive,
                "sportline-event-id": _unref(sportlineEventId),
                "stream-url": _unref(streamUrl),
                "is-floating-mode-allowed": _ctx.isFloatingModeAllowed,
                "is-floating-mode-enabled": _unref(isFloatingModeEnabled),
                "do-show-stream-preview-info": _unref(doShowStreamPreviewInfo),
                onStreamSetup: _unref(reloadStream),
                onStreamStarted: _unref(onStreamStarted),
                onStreamRelease: _unref(releaseStream)
            }, null, 8, [
                "is-active",
                "sportline-event-id",
                "stream-url",
                "is-floating-mode-allowed",
                "is-floating-mode-enabled",
                "do-show-stream-preview-info",
                "onStreamSetup",
                "onStreamStarted",
                "onStreamRelease"
            ])) : _ctx.isActive ? (_openBlock(), _createBlock(NoActiveStreamWidget, {
                key: 1
            })) : _createCommentVNode("", true);
    }
});
