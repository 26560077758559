// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sport-event-related-list_SKIEa{background-color:var(--Layer0)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sport-event-related-list": `sport-event-related-list_SKIEa`
};
export default ___CSS_LOADER_EXPORT___;
