// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.headline-layout_riiVb{padding:8px}.headline-layout__content_EAA3v{display:flex;flex-direction:column;align-items:center;justify-content:flex-start}.headline-layout__logo_zAnVM{padding:14px;box-shadow:0 1px 2px #00000026,0 0 6px #0000001a}@media(max-width:480px){.headline-layout__logo_zAnVM{padding:8px}}.headline-layout-tabs_Euh2L{flex-basis:36px;width:100%;padding-bottom:8px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headline-layout": `headline-layout_riiVb`,
	"headline-layout__content": `headline-layout__content_EAA3v`,
	"headline-layout__logo": `headline-layout__logo_zAnVM`,
	"headline-layout-tabs": `headline-layout-tabs_Euh2L`
};
export default ___CSS_LOADER_EXPORT___;
