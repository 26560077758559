// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sport-event-loader-wrapper_kfIrJ{z-index:46;position:absolute;right:0;left:0;height:100%;margin-top:44px;background-color:var(--Layer0)}html.app__layout--desktop .sport-event-loader-wrapper_kfIrJ{margin-top:0}.loader-masked_aXvKx{position:absolute;top:0;right:0;bottom:0;left:0;display:flex;justify-content:center;padding-top:150px;background-color:var(--OpacityLayer0)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sport-event-loader-wrapper": `sport-event-loader-wrapper_kfIrJ`,
	"loader-masked": `loader-masked_aXvKx`
};
export default ___CSS_LOADER_EXPORT___;
