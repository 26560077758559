// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sportline-breadcrumbs__inner_V39VK{display:flex;align-items:flex-start;justify-content:space-between;min-height:56px;padding:8px 0}.sportline-breadcrumbs__content_jXmlZ{z-index:55;display:flex;flex-flow:row wrap;gap:4px;padding:0;margin:0;list-style:none}.sportline-event-breadcrumbs__sidebar_bRjY4{z-index:65;position:absolute;top:0;bottom:0;width:252px;margin:8px 16px 0 0;background-color:var(--Layer0);box-shadow:var(--BreadcrumbBoxShadow);transition:transform .15s cubic-bezier(.25,.8,.25,1);transform:translateX(-100vw)}.sportline-event-breadcrumbs--sidebar-open_zT5jn .sportline-event-breadcrumbs__sidebar_bRjY4{transform:translateX(0)}.sportline-event-breadcrumbs__sidebar-close_lrIyv{position:absolute;top:8px;right:-48px}.sportline-event-breadcrumbs__sidebar-close-button__kPkK{color:var(--Layer1);background-color:var(--TextDefault);border-radius:5px}html.app__layout--desktop .sportline-event-breadcrumbs__sidebar-close-button__kPkK:hover:not([disabled]){color:var(--Layer1);background-color:var(--TextDefault)}.sportline-event-breadcrumbs__hamburger_EkVgF{display:none;margin-right:4px;background-color:var(--Layer1);border-radius:5px}.sportline-event-breadcrumbs__hamburger_EkVgF:hover{background-color:var(--Highlight)}@media(min-width:1025px){.sportline-event-breadcrumbs__hamburger_EkVgF{display:block}}.sportline-event-breadcrumbs__left_V5kKA{display:flex;align-items:flex-start}.sportline-event-breadcrumbs__mask_ziTLu{position:fixed;top:0;bottom:0;z-index:64;display:block;width:100%;min-width:488px;max-width:1118px;content:"";background-color:var(--Layer0);opacity:.7}.sportline-event-breadcrumbs--breadcrumb-open_M2gHa .sportline-event-breadcrumbs__mask_ziTLu{position:absolute;top:auto;width:100%;height:100%}.opacity-enter-active_cpmJh{transition:opacity .25s ease-in-out}.opacity-enter-from_tI1BX,.opacity-leave-to_riXVV{opacity:1}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sportline-breadcrumbs__inner": `sportline-breadcrumbs__inner_V39VK`,
	"sportline-breadcrumbs__content": `sportline-breadcrumbs__content_jXmlZ`,
	"sportline-event-breadcrumbs__sidebar": `sportline-event-breadcrumbs__sidebar_bRjY4`,
	"sportline-event-breadcrumbs--sidebar-open": `sportline-event-breadcrumbs--sidebar-open_zT5jn`,
	"sportline-event-breadcrumbs__sidebar-close": `sportline-event-breadcrumbs__sidebar-close_lrIyv`,
	"sportline-event-breadcrumbs__sidebar-close-button": `sportline-event-breadcrumbs__sidebar-close-button__kPkK`,
	"sportline-event-breadcrumbs__hamburger": `sportline-event-breadcrumbs__hamburger_EkVgF`,
	"sportline-event-breadcrumbs__left": `sportline-event-breadcrumbs__left_V5kKA`,
	"sportline-event-breadcrumbs__mask": `sportline-event-breadcrumbs__mask_ziTLu`,
	"sportline-event-breadcrumbs--breadcrumb-open": `sportline-event-breadcrumbs--breadcrumb-open_M2gHa`,
	"opacity-enter-active": `opacity-enter-active_cpmJh`,
	"opacity-enter-from": `opacity-enter-from_tI1BX`,
	"opacity-leave-to": `opacity-leave-to_riXVV`
};
export default ___CSS_LOADER_EXPORT___;
