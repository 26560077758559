// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.virtual-sport-widget_ARIgr{position:relative;display:flex;align-items:flex-start;justify-content:center;width:100%;min-height:100vh}.virtual-sport-widget__loader_ca2bT{position:absolute;top:0;right:0;left:0;display:flex;align-items:center;justify-content:center;height:453px;padding:50px 0;background:var(--Layer0)}.virtual-sport-widget__loader-image_HrxiU{max-width:255px}.virtual-sport-widget-frame_DOTl4{min-height:100vh}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"virtual-sport-widget": `virtual-sport-widget_ARIgr`,
	"virtual-sport-widget__loader": `virtual-sport-widget__loader_ca2bT`,
	"virtual-sport-widget__loader-image": `virtual-sport-widget__loader-image_HrxiU`,
	"virtual-sport-widget-frame": `virtual-sport-widget-frame_DOTl4`
};
export default ___CSS_LOADER_EXPORT___;
