// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sportline-event_iGncq{min-height:var(--list-event-height)}.sportline-event--before-event-gap_AMKct{margin-bottom:var(--list-events-gap)}.sportline-event--before-league-gap_cDJi9{margin-bottom:var(--list-leagues-gap)}.sportline-event--before-region-gap_xuywP{margin-bottom:var(--list-regions-gap)}.sportline-event--before-sport-gap_NdzEE{margin-bottom:var(--list-sports-gap)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sportline-event": `sportline-event_iGncq`,
	"sportline-event--before-event-gap": `sportline-event--before-event-gap_AMKct`,
	"sportline-event--before-league-gap": `sportline-event--before-league-gap_cDJi9`,
	"sportline-event--before-region-gap": `sportline-event--before-region-gap_xuywP`,
	"sportline-event--before-sport-gap": `sportline-event--before-sport-gap_NdzEE`
};
export default ___CSS_LOADER_EXPORT___;
