// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.primary-market__name--after-countdown_xGJ_M,.primary-market__name--after-timeline_y44u0{margin-top:-8px}.sport-event-details-headline_KtcEs{margin-bottom:8px;overflow:hidden;color:var(--White);border-radius:5px}.sport-event-details-headline__widget-button_xRdUf{z-index:5;position:absolute;top:4px;left:4px}.sport-event-details-headline--light_JvbKL{color:var(--Black)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primary-market__name--after-countdown": `primary-market__name--after-countdown_xGJ_M`,
	"primary-market__name--after-timeline": `primary-market__name--after-timeline_y44u0`,
	"sport-event-details-headline": `sport-event-details-headline_KtcEs`,
	"sport-event-details-headline__widget-button": `sport-event-details-headline__widget-button_xRdUf`,
	"sport-event-details-headline--light": `sport-event-details-headline--light_JvbKL`
};
export default ___CSS_LOADER_EXPORT___;
