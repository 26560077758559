// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.please-login-widget_gKsOU{flex-direction:column;gap:24px;width:100%;padding:16px;background-color:var(--Layer1)}.please-login-icon-holder_y6lyf,.please-login-widget_gKsOU{display:flex;align-items:center;justify-content:center}.please-login-icon-holder_y6lyf{color:var(--TextSecondary);fill:var(--TextSecondary)}.please-login-title_H8Bxf{font-size:14px;font-weight:500;line-height:16px;letter-spacing:.25px;color:var(--TextDefault);text-align:center}.please-login-title__holder_HVc4d{display:flex;flex-direction:column;gap:12px;align-items:center}.please-login-buttons_oQFyN{display:flex;gap:12px;width:100%}.please-login-buttons__button_owtyW{flex:1}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"please-login-widget": `please-login-widget_gKsOU`,
	"please-login-icon-holder": `please-login-icon-holder_y6lyf`,
	"please-login-title": `please-login-title_H8Bxf`,
	"please-login-title__holder": `please-login-title__holder_HVc4d`,
	"please-login-buttons": `please-login-buttons_oQFyN`,
	"please-login-buttons__button": `please-login-buttons__button_owtyW`
};
export default ___CSS_LOADER_EXPORT___;
