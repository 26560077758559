// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.last-games-results-match-statistic__last-matches_BSUGL{margin-bottom:4px}.sport-event-statistic-subtitle_Z2KDY{font-size:13px;font-weight:500;line-height:16px;text-transform:uppercase;letter-spacing:normal;width:100%;padding:8px 0;color:var(--TextDefault);text-align:center;text-transform:none}.sport-event-statistic-last-games_yiz4A{padding-bottom:16px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"last-games-results-match-statistic__last-matches": `last-games-results-match-statistic__last-matches_BSUGL`,
	"sport-event-statistic-subtitle": `sport-event-statistic-subtitle_Z2KDY`,
	"sport-event-statistic-last-games": `sport-event-statistic-last-games_yiz4A`
};
export default ___CSS_LOADER_EXPORT___;
