// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.headline-layout__background_wcvN0{color:var(--BasedOnSportTextColored);background-color:#22262a;background-repeat:no-repeat;background-position:50%;background-size:cover}html.app__layout--desktop .headline-layout_FjmfV{display:flex;gap:4px;justify-content:space-between;overflow:hidden;border-radius:5px;transition:height .3s cubic-bezier(.4,0,.2,1)}html.app__layout--desktop .headline-layout__info_T4rer{display:flex;flex:1;flex-direction:column;align-items:stretch;justify-content:center;border-radius:5px 0 0}html.app__layout--desktop .headline-layout__info_T4rer:last-child{border-radius:5px}html.app__layout--desktop .headline-layout__info--full-height_VMcKb{height:100%}html.app__layout--desktop .headline-layout__info--full-width_Ea2VZ{width:100%;margin-right:0}.headline-layout_FjmfV{color:var(--White)}.headline-layout--live-auto_FRxdc,.headline-layout--live-auto_FRxdc .headline-layout__inplay-component_fqPs7{min-height:200px}.headline-layout--live-default_P1MLz{height:204px;max-height:200px}.headline-layout--live-default_P1MLz .headline-layout__inplay-component_fqPs7{min-height:200px}.headline-layout--live-low_RVEYA{height:172px;max-height:172px}.headline-layout--live-low_RVEYA .headline-layout__inplay-component_fqPs7{min-height:172px}.headline-layout--prematch-default_Fq0Mg{height:172px;max-height:172px}.headline-layout--prematch-low_bfuqs{height:142px;max-height:142px}.headline-layout--prematch--large_HBsJd{height:204px;max-height:200px}.headline-layout__info_T4rer{flex-basis:50%;min-width:386px}.headline-layout__inplay-component_fqPs7{position:relative;flex-basis:50%;min-width:320px;height:0;overflow:hidden}.headline-layout__inplay-component-content_bV02C{position:absolute;top:0;left:0;width:100%;height:100%}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headline-layout__background": `headline-layout__background_wcvN0`,
	"headline-layout": `headline-layout_FjmfV`,
	"headline-layout__info": `headline-layout__info_T4rer`,
	"headline-layout__info--full-height": `headline-layout__info--full-height_VMcKb`,
	"headline-layout__info--full-width": `headline-layout__info--full-width_Ea2VZ`,
	"headline-layout--live-auto": `headline-layout--live-auto_FRxdc`,
	"headline-layout__inplay-component": `headline-layout__inplay-component_fqPs7`,
	"headline-layout--live-default": `headline-layout--live-default_P1MLz`,
	"headline-layout--live-low": `headline-layout--live-low_RVEYA`,
	"headline-layout--prematch-default": `headline-layout--prematch-default_Fq0Mg`,
	"headline-layout--prematch-low": `headline-layout--prematch-low_bfuqs`,
	"headline-layout--prematch--large": `headline-layout--prematch--large_HBsJd`,
	"headline-layout__inplay-component-content": `headline-layout__inplay-component-content_bV02C`
};
export default ___CSS_LOADER_EXPORT___;
