import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, renderList as _renderList, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { toRef } from 'vue';
import MatchStatisticResultIndicator from 'web/src/modules/sportline/submodules/event-details/components/statistic/MatchStatisticResultIndicator.vue';
import StatisticTeamLogo from 'web/src/modules/sportline/submodules/event-statistic/components/StatisticTeamLogo.vue';
import { useSportEventMatchStatistic } from './useSportEventMatchStatistic';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SportEventMatchStatistic',
    props: {
        match: {},
        displayedForTeam: {
            default: null
        },
        sportFamily: {}
    },
    setup (__props) {
        const props = __props;
        const { isLive, host, guest, resultScore, isHostWinner, isGuestWinner, setsScore, date } = useSportEventMatchStatistic({
            match: toRef(props, 'match'),
            sportFamily: toRef(props, 'sportFamily')
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['sport-event-match-statistic'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['sport-event-match-statistic__left'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['sport-event-match-statistic__match-info'])
                    }, [
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['sport-event-match-statistic__meta'])
                        }, [
                            _createElementVNode("span", {
                                class: _normalizeClass({
                                    [_ctx.$style['sport-event-match-statistic__date']]: true,
                                    [_ctx.$style['sport-event-match-statistic__date--live']]: _unref(isLive)
                                })
                            }, _toDisplayString(_unref(date)), 3),
                            _unref(isLive) && _ctx.match.gameScore ? (_openBlock(), _createElementBlock("span", {
                                key: 0,
                                class: _normalizeClass(_ctx.$style['sport-event-match-statistic__current-score'])
                            }, _toDisplayString(_ctx.match.gameScore), 3)) : _createCommentVNode("", true)
                        ], 2),
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['sport-event-match-statistic__teams'])
                        }, [
                            _unref(host) && _unref(guest) ? (_openBlock(), _createElementBlock(_Fragment, {
                                key: 0
                            }, [
                                _createElementVNode("div", {
                                    class: _normalizeClass({
                                        [_ctx.$style['sport-event-match-statistic__team']]: true,
                                        [_ctx.$style['sport-event-match-statistic__team--live']]: _unref(isLive),
                                        [_ctx.$style['sport-event-match-statistic__team--winner']]: !_unref(isLive) && _unref(isHostWinner)
                                    })
                                }, [
                                    _createVNode(StatisticTeamLogo, {
                                        class: _normalizeClass(_ctx.$style['sport-event-match-statistic__team-logo']),
                                        team: _unref(host)
                                    }, null, 8, [
                                        "class",
                                        "team"
                                    ]),
                                    _createElementVNode("span", {
                                        class: _normalizeClass(_ctx.$style['sport-event-match-statistic__team-name'])
                                    }, _toDisplayString(_unref(host).name), 3)
                                ], 2),
                                _createElementVNode("div", {
                                    class: _normalizeClass({
                                        [_ctx.$style['sport-event-match-statistic__team']]: true,
                                        [_ctx.$style['sport-event-match-statistic__team--live']]: _unref(isLive),
                                        [_ctx.$style['sport-event-match-statistic__team--winner']]: !_unref(isLive) && _unref(isGuestWinner)
                                    })
                                }, [
                                    _createVNode(StatisticTeamLogo, {
                                        class: _normalizeClass(_ctx.$style['sport-event-match-statistic__team-logo']),
                                        team: _unref(guest)
                                    }, null, 8, [
                                        "class",
                                        "team"
                                    ]),
                                    _createElementVNode("span", {
                                        class: _normalizeClass(_ctx.$style['sport-event-match-statistic__team-name'])
                                    }, _toDisplayString(_unref(guest).name), 3)
                                ], 2)
                            ], 64)) : _createCommentVNode("", true)
                        ], 2)
                    ], 2),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['sport-event-match-statistic__scores-holder'])
                    }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(setsScore), (setScore, index)=>(_openBlock(), _createElementBlock("div", {
                                key: `${index}_${setScore[0]}_${setScore[1]}}`,
                                class: _normalizeClass(_ctx.$style['sport-event-match-statistic__scores'])
                            }, [
                                _createElementVNode("div", {
                                    class: _normalizeClass(_ctx.$style['sport-event-match-statistic__score'])
                                }, _toDisplayString(setScore[0]), 3),
                                _createElementVNode("div", {
                                    class: _normalizeClass(_ctx.$style['sport-event-match-statistic__score'])
                                }, _toDisplayString(setScore[1]), 3)
                            ], 2))), 128)),
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['sport-event-match-statistic__scores'])
                        }, [
                            _createElementVNode("div", {
                                class: _normalizeClass({
                                    [_ctx.$style['sport-event-match-statistic__score']]: true,
                                    [_ctx.$style['sport-event-match-statistic__score--live']]: _unref(isLive),
                                    [_ctx.$style['sport-event-match-statistic__score--winner']]: !_unref(isLive) && _unref(isHostWinner)
                                })
                            }, _toDisplayString(_unref(resultScore)[0]), 3),
                            _createElementVNode("div", {
                                class: _normalizeClass({
                                    [_ctx.$style['sport-event-match-statistic__score']]: true,
                                    [_ctx.$style['sport-event-match-statistic__score--live']]: _unref(isLive),
                                    [_ctx.$style['sport-event-match-statistic__score--winner']]: !_unref(isLive) && _unref(isGuestWinner)
                                })
                            }, _toDisplayString(_unref(resultScore)[1]), 3)
                        ], 2)
                    ], 2)
                ], 2),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['sport-event-match-statistic__right'])
                }, [
                    _createVNode(MatchStatisticResultIndicator, {
                        match: _ctx.match,
                        "displayed-for-team": _ctx.displayedForTeam
                    }, null, 8, [
                        "match",
                        "displayed-for-team"
                    ])
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'SportEventMatchStatistic'
                ]
            ]);
        };
    }
});
