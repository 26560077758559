// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sport-header_uCDWn{font-size:18px;font-weight:500;line-height:20px;letter-spacing:normal;padding:18px 0 18px 8px;color:var(--TextDefault);background-color:var(--Layer0)}.regions_Fo6s3{display:flex;flex-direction:column;gap:4px;align-items:stretch}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sport-header": `sport-header_uCDWn`,
	"regions": `regions_Fo6s3`
};
export default ___CSS_LOADER_EXPORT___;
