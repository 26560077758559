import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock } from "vue";
import { toRef, onBeforeUnmount } from 'vue';
import { VLazyIframe as VIframe } from 'web/src/components/Iframe';
import { VLoaderDelayed } from 'web/src/components/Loader';
import VJumbotron from 'web/src/components/Jumbotron/VJumbotron/VJumbotron.vue';
import WidgetWrapper from 'web/src/modules/sportline/submodules/widgets/views/WidgetWrapper.vue';
import useSportradarLiveMatchTrackerWidget from './useSportradarLiveMatchTrackerWidget';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SportradarLiveMatchTrackerWidget',
    props: {
        sportEventDetails: {},
        backgroundColor: {},
        isFloatingModeEnabled: {
            type: Boolean
        },
        isFloatingModeAllowed: {
            type: Boolean
        },
        isActive: {
            type: Boolean
        },
        aspectRatio: {}
    },
    emits: [
        "size-changed"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const sportEventDetails = toRef(()=>props.sportEventDetails);
        const isFloatingModeEnabled = toRef(()=>props.isFloatingModeEnabled);
        const isFloatingModeAllowed = toRef(()=>props.isFloatingModeAllowed);
        const aspectRatio = toRef(()=>props.aspectRatio);
        const isActive = toRef(()=>props.isActive);
        const { widgetLoaded, readyToLoading, showFailureMessage, iframeWidgetSrc, iframeName, additionalQueryStringParameters, onIframeMounted, releaseMessageBus } = useSportradarLiveMatchTrackerWidget({
            sportEventDetails,
            isActive
        }, {
            onSizeChanged (size) {
                emit('size-changed', size);
            }
        });
        onBeforeUnmount(()=>{
            releaseMessageBus();
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['live-widget-slide'])
            }, [
                _createVNode(WidgetWrapper, {
                    class: _normalizeClass(_ctx.$style['live-widget-slide__component']),
                    "is-floating-mode-allowed": isFloatingModeAllowed.value,
                    "is-floating-mode-enabled": isFloatingModeEnabled.value && _unref(widgetLoaded)
                }, {
                    default: _withCtx(()=>[
                            _unref(readyToLoading) && _unref(widgetLoaded) ? _createCommentVNode("", true) : (_openBlock(), _createBlock(_unref(VLoaderDelayed), {
                                key: 0,
                                class: _normalizeClass(_ctx.$style['live-widget-slide__loader'])
                            }, null, 8, [
                                "class"
                            ])),
                            _unref(showFailureMessage) ? (_openBlock(), _createBlock(VJumbotron, {
                                key: 1,
                                heading: _ctx.$t('WEB2_FAILED_TO_LOAD_LIVEWIDGET')
                            }, null, 8, [
                                "heading"
                            ])) : _createCommentVNode("", true),
                            _unref(readyToLoading) ? _withDirectives((_openBlock(), _createBlock(_unref(VIframe), {
                                key: _unref(iframeWidgetSrc),
                                src: _unref(iframeWidgetSrc),
                                "height-aspect-ratio": aspectRatio.value,
                                "full-height": true,
                                "iframe-class": _ctx.$style['live-widget-slide__iframe'],
                                "full-width": "",
                                name: _unref(iframeName),
                                "additional-query-string-parameters": _unref(additionalQueryStringParameters),
                                onVnodeMounted: _unref(onIframeMounted)
                            }, null, 8, [
                                "src",
                                "height-aspect-ratio",
                                "full-height",
                                "iframe-class",
                                "name",
                                "additional-query-string-parameters",
                                "onVnodeMounted"
                            ])), [
                                [
                                    _vShow,
                                    _unref(widgetLoaded)
                                ]
                            ]) : _createCommentVNode("", true)
                        ]),
                    _: 1
                }, 8, [
                    "class",
                    "is-floating-mode-allowed",
                    "is-floating-mode-enabled"
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'SportradarLiveMatchTrackerWidget'
                ]
            ]);
        };
    }
});
