import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "title"
];
import { toRef } from 'vue';
import LeaguePlayoffMatchesBlock from './LeaguePlayoffMatchesBlock.vue';
import { useLeaguePlayoffRound } from './useLeaguePlayoffRound';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LeaguePlayoffRound',
    props: {
        round: {},
        isLastRound: {
            type: Boolean
        }
    },
    setup (__props) {
        const props = __props;
        const { blockPairs } = useLeaguePlayoffRound({
            round: toRef(props, 'round')
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['league-playoff-round'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['league-playoff-round__title']),
                    title: _ctx.round.description ?? void 0
                }, _toDisplayString(_ctx.round.description), 11, _hoisted_1),
                _unref(blockPairs).length > 0 ? (_openBlock(true), _createElementBlock(_Fragment, {
                    key: 0
                }, _renderList(_unref(blockPairs), (pair, pairIndex)=>(_openBlock(), _createElementBlock("div", {
                        key: pairIndex,
                        class: _normalizeClass(_ctx.$style['league-playoff-round__block-pair'])
                    }, [
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['league-playoff-round__block-pair-content'])
                        }, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(pair, (block, blockIndex)=>(_openBlock(), _createElementBlock("div", {
                                    key: blockIndex,
                                    class: _normalizeClass(_ctx.$style['league-playoff-round__block-holder'])
                                }, [
                                    block ? (_openBlock(), _createBlock(LeaguePlayoffMatchesBlock, {
                                        key: 0,
                                        block: block
                                    }, null, 8, [
                                        "block"
                                    ])) : _createCommentVNode("", true)
                                ], 2))), 128))
                        ], 2),
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['league-playoff-round__arrow'])
                        }, [
                            _ctx.isLastRound ? _createCommentVNode("", true) : (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: _normalizeClass(_ctx.$style['league-playoff-round__arrow-line'])
                            }, null, 2))
                        ], 2)
                    ], 2))), 128)) : (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: _normalizeClass(_ctx.$style['league-playoff-round__block-pair'])
                }, null, 2))
            ], 2)), [
                [
                    _directive_auto_id,
                    'LeaguePlayoffRound'
                ]
            ]);
        };
    }
});
