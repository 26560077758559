// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.statistic-chart-labels_fmf74{display:flex;justify-content:space-between}.statistic-chart-labels__item_cag61{min-width:20%;text-align:center}.statistic-chart-labels__item_cag61:first-child{text-align:left}.statistic-chart-labels__item_cag61:last-child{text-align:right}.statistic-chart-label-indicator_hDVbl{display:inline-block}.statistic-chart-label-indicator_hDVbl:before{display:inline-block;width:8px;height:8px;border-radius:2px;content:" ";background:#0000}.statistic-chart-label-indicator--home_zMGWS:before{background:var(--StatsA)}.statistic-chart-label-indicator--draw_blLSu:before{background:var(--StatsDraw)}.statistic-chart-label-indicator--away_BWodS:before{background:var(--StatsB)}.join-matches-statistic_nKp85{padding:4px;background-color:var(--Layer2);border-radius:5px}.join-matches-statistic-goals__container_J9j66{padding:12px 4px 4px}.join-matches-statistic-goals__label_emzZj{font-size:12px;font-weight:500;line-height:12px;letter-spacing:normal;color:var(--TextDefault)}.join-matches-statistic-graph__container_PC9XJ{padding:8px 4px}.join-matches-statistic-wins__container_iwgaQ{padding:0 4px 8px}.join-matches-statistic-wins__label_q8LNM{font-size:12px;font-weight:500;line-height:12px;letter-spacing:normal;color:var(--TextPrimary)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"statistic-chart-labels": `statistic-chart-labels_fmf74`,
	"statistic-chart-labels__item": `statistic-chart-labels__item_cag61`,
	"statistic-chart-label-indicator": `statistic-chart-label-indicator_hDVbl`,
	"statistic-chart-label-indicator--home": `statistic-chart-label-indicator--home_zMGWS`,
	"statistic-chart-label-indicator--draw": `statistic-chart-label-indicator--draw_blLSu`,
	"statistic-chart-label-indicator--away": `statistic-chart-label-indicator--away_BWodS`,
	"join-matches-statistic": `join-matches-statistic_nKp85`,
	"join-matches-statistic-goals__container": `join-matches-statistic-goals__container_J9j66`,
	"join-matches-statistic-goals__label": `join-matches-statistic-goals__label_emzZj`,
	"join-matches-statistic-graph__container": `join-matches-statistic-graph__container_PC9XJ`,
	"join-matches-statistic-wins__container": `join-matches-statistic-wins__container_iwgaQ`,
	"join-matches-statistic-wins__label": `join-matches-statistic-wins__label_q8LNM`
};
export default ___CSS_LOADER_EXPORT___;
