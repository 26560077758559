// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.league-matches-results__content_g2pTZ{display:grid;grid-template-columns:1fr 1fr;gap:8px}@media(max-width:699px){.league-matches-results__content_g2pTZ{grid-template-columns:1fr}}.matches-results-list_a6mc6{padding:0 8px 8px;background-color:var(--Layer2);border-radius:5px}.matches-results-list__title_qhAhP{font-size:14px;font-weight:500;line-height:16px;letter-spacing:.25px;padding:15px 0;color:var(--TextDefault);text-align:left}.matches-results-list__item_O0XTm{padding:8px 0}.match-result-item_arxZg{display:grid;grid-template-columns:1fr;row-gap:8px;border-top:1px solid var(--Layer1)}.match-result-item__values_rubdw{display:grid;grid-template-columns:1fr 1fr 1fr}.match-result-item__value_HCH4p{font-size:14px;font-weight:500;line-height:16px;letter-spacing:.25px;text-align:center}.match-result-item__value_HCH4p:first-child{text-align:left}.match-result-item__value_HCH4p:last-child{text-align:right}.match-result-label_dRCey{font-size:14px;font-weight:500;line-height:16px;letter-spacing:.25px;display:inline-block;padding:2px 4px;color:var(--TextPrimary);border-radius:2px}.match-result-label--light-background_svfkV{color:var(--TextDefault);background-color:var(--OpacityBrandDefault)}.match-result-label--dark-background_lpALR{color:var(--TextDefault);background-color:var(--OpacityLayer1)}.match-result-expand_B3cqK{grid-column:1/-1}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"league-matches-results__content": `league-matches-results__content_g2pTZ`,
	"matches-results-list": `matches-results-list_a6mc6`,
	"matches-results-list__title": `matches-results-list__title_qhAhP`,
	"matches-results-list__item": `matches-results-list__item_O0XTm`,
	"match-result-item": `match-result-item_arxZg`,
	"match-result-item__values": `match-result-item__values_rubdw`,
	"match-result-item__value": `match-result-item__value_HCH4p`,
	"match-result-label": `match-result-label_dRCey`,
	"match-result-label--light-background": `match-result-label--light-background_svfkV`,
	"match-result-label--dark-background": `match-result-label--dark-background_lpALR`,
	"match-result-expand": `match-result-expand_B3cqK`
};
export default ___CSS_LOADER_EXPORT___;
