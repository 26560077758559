// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .live-widget-slide_zlCC4{display:flex;align-items:baseline;justify-content:center;width:100%;height:100%}html.app__layout--desktop .live-widget-slide__component_JEy6h{min-height:204px;flex:none;width:100%;height:100%}html.app__layout--desktop .live-widget-slide__loader_VyGKz{display:flex;align-items:center;justify-content:center;height:100%;min-height:200px}html.app__layout--desktop .live-widget-slide__iframe_EUFO6{min-height:280px;max-height:350px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"live-widget-slide": `live-widget-slide_zlCC4`,
	"live-widget-slide__component": `live-widget-slide__component_JEy6h`,
	"live-widget-slide__loader": `live-widget-slide__loader_VyGKz`,
	"live-widget-slide__iframe": `live-widget-slide__iframe_EUFO6`
};
export default ___CSS_LOADER_EXPORT___;
