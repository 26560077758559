// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.post-match-statistic-incident_pNMFW{padding:8px;background-color:var(--Layer2)}.post-match-statistic-incident_pNMFW:first-child{border-top-left-radius:5px;border-top-right-radius:5px}.post-match-statistic-incident_pNMFW:last-child{border-bottom-right-radius:5px;border-bottom-left-radius:5px}.post-match-statistic-incident_pNMFW:not(:last-child){margin-bottom:1px}.post-match-statistic-incident--break_uFiIV{display:flex;justify-content:center;background-color:var(--Highlight)}.post-match-statistic-incident--match-completed_pd0Cp{display:flex;justify-content:center;background-color:var(--Layer2)}.post-match-statistic-incident__label_veXFt{color:var(--TextDefault)}.post-match-statistic-incident__label_veXFt,.post-match-statistic-incident__score_szsII{font-size:13px;font-weight:500;line-height:16px;letter-spacing:normal;margin:0 4px}.post-match-statistic-incident__score_szsII{color:var(--TextSecondary)}.post-match-statistic-incidents-list-toggle_mXkK0{margin-top:8px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"post-match-statistic-incident": `post-match-statistic-incident_pNMFW`,
	"post-match-statistic-incident--break": `post-match-statistic-incident--break_uFiIV`,
	"post-match-statistic-incident--match-completed": `post-match-statistic-incident--match-completed_pd0Cp`,
	"post-match-statistic-incident__label": `post-match-statistic-incident__label_veXFt`,
	"post-match-statistic-incident__score": `post-match-statistic-incident__score_szsII`,
	"post-match-statistic-incidents-list-toggle": `post-match-statistic-incidents-list-toggle_mXkK0`
};
export default ___CSS_LOADER_EXPORT___;
