// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.redirect-from-empty-page-info_XHtql{position:relative;display:flex;flex-direction:column;align-items:center;padding:16px;text-align:center;background-color:var(--OpacityLayer1);border-radius:5px}.redirect-from-empty-page-info__title__Q5Rz{font-size:18px;font-weight:500;line-height:28px;letter-spacing:normal;padding-bottom:8px}.redirect-from-empty-page-info__description_YN3N1{font-size:14px;font-weight:400;line-height:24px;letter-spacing:.25px}.redirect-from-empty-page-info__close-button_E9lsu{position:absolute;top:15px;right:15px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"redirect-from-empty-page-info": `redirect-from-empty-page-info_XHtql`,
	"redirect-from-empty-page-info__title": `redirect-from-empty-page-info__title__Q5Rz`,
	"redirect-from-empty-page-info__description": `redirect-from-empty-page-info__description_YN3N1`,
	"redirect-from-empty-page-info__close-button": `redirect-from-empty-page-info__close-button_E9lsu`
};
export default ___CSS_LOADER_EXPORT___;
