// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .mini-board_QB3dP{margin-top:-82px;opacity:0;transition:margin-top .2s ease-in-out,opacity .2s ease-in-out}html.app__layout--desktop .mini-board--animation-hide_DsCUD{transition:margin-top .2s ease-in,opacity .2s ease-in}html.app__layout--desktop .mini-board__container_Wem6B{padding:0 16px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mini-board": `mini-board_QB3dP`,
	"mini-board--animation-hide": `mini-board--animation-hide_DsCUD`,
	"mini-board__container": `mini-board__container_Wem6B`
};
export default ___CSS_LOADER_EXPORT___;
