// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sport-event-details-dropdown_u1VWk{height:100%}.sport-event-details-dropdown__content_Sv9mH{right:0;z-index:55;height:100%;background-color:var(--OpacityLayer0)}.sport-event-details-dropdown__background_oPoU6{position:absolute;top:0;right:0;bottom:0;left:0;height:100%;opacity:.7}.slide-enter-active_ovja2{transition-timing-function:ease-in;transition-duration:.2s}.slide-leave-active_VDVtY{transition-timing-function:cubic-bezier(0,1,.5,1);transition-duration:.2s}.slide-enter-to_ls1w6,.slide-leave-from_OMSrQ{transform:scaleY(1);transform-origin:top}.slide-enter-from_xye8I,.slide-leave-to_j07__{overflow:hidden;transform:scaleY(0);transform-origin:top}.fade-enter-active_SpGVR,.fade-leave-active_NqpDp{transition-duration:.1s}.fade-enter-to_wby1X,.fade-leave-from_h3kyH{opacity:1}.fade-enter-from_xX5kB,.fade-leave-to_tAqoA{opacity:0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sport-event-details-dropdown": `sport-event-details-dropdown_u1VWk`,
	"sport-event-details-dropdown__content": `sport-event-details-dropdown__content_Sv9mH`,
	"sport-event-details-dropdown__background": `sport-event-details-dropdown__background_oPoU6`,
	"slide-enter-active": `slide-enter-active_ovja2`,
	"slide-leave-active": `slide-leave-active_VDVtY`,
	"slide-enter-to": `slide-enter-to_ls1w6`,
	"slide-leave-from": `slide-leave-from_OMSrQ`,
	"slide-enter-from": `slide-enter-from_xye8I`,
	"slide-leave-to": `slide-leave-to_j07__`,
	"fade-enter-active": `fade-enter-active_SpGVR`,
	"fade-leave-active": `fade-leave-active_NqpDp`,
	"fade-enter-to": `fade-enter-to_wby1X`,
	"fade-leave-from": `fade-leave-from_h3kyH`,
	"fade-enter-from": `fade-enter-from_xX5kB`,
	"fade-leave-to": `fade-leave-to_tAqoA`
};
export default ___CSS_LOADER_EXPORT___;
